
.newsitem {
    .date {
        font-weight: bold;
    }

    p img {
        float: left!important;
        margin-right: 20px!important;
        margin-bottom: 20px!important;
    }
}
