.searchWithinContainerInner{
    label {
        font-weight: normal;
        input[type="radio"],
        input[type="checkbox"]{
            margin: 0 7px;
            vertical-align: middle;
        }
    }
    .list-unstyled {
    padding-left:0;
    padding-right:0;
    }
}


/*For Data & Media*/
.dataandmedia{
    .singleTableContainer,.singleFigureContainer{
        overflow: hidden;
        border-top: 1px solid @grey-light-custom;
        padding: 20px 0;
        .fleft{
            padding-right: 20px;
            display: table-cell;
            text-align: center;
            img{
                max-width: 100%
            }
        }
        .tableCaptionContainer,.figureCaptionContainer{
            display: table-cell;    
            vertical-align: top;
            .tabTitle,.figTitle{
                font-size: 18px;
            }
            .tabDesc,.figDesc{
                display: block;
            }
        }
    }

    .singleFigureContainer{
        + .singleFigureContainer {
            border-top: none; 
        }
        border-bottom: 1px solid @grey-light-custom;
        margin-bottom: 18px;
    }

    .figureCaptionContainer{
        margin-top: 40px; 
    }

    .fright{
        float: right;
    }
}


@media (max-width: @screen-xs-max) {
    .dataandmedia{
        .singleTableContainer,.singleFigureContainer{
            .fleft,.tableCaptionContainer,.figureCaptionContainer{
                display: block;
                margin-top: 10px;
            }
        } 
    }
}






