//override existing code see SABINET-241
#previewWrapper {
    width:100%;
    .previewMagnify {
                display:none;
    }
    .previewthumbnailink img {
        width:100%;
    }
}


