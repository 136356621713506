.mostrecentarticles p {
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.issue-listing{
	float: right;
	min-height: 284px;
}

.clear-division{
	clear: both;
}

.ERA-row{
	margin: 0px -15px;
 }

 #tabbedpages{
 	.journaloverviewtab{
 		margin-bottom: 20px;
 	}
 }

.ft-download-content__form > * {
    //this i believe will work for images
    min-width: 30px;
    &:hover {
        cursor: pointer;
    }
}

.ft-download-form--disabled > * {
    opacity: 0.5;
     &:hover {
        cursor: not-allowed;
    }
}

.ft-download-content__form  i {
   font-size: 30px;
   font-weight:normal;
   text-decoration: none;   
}

.ft-download-content__form--pdf i {
    color: #ff0000;
}

.ft-download-content__form button {
	.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
	.btn;
	margin-left:20px;
	i {
       float: none;
       color: inherit;
       font-size: initial;
       font-weight:normal;
	}
}


span.heading1{
	display: block;
	font-size: 16px;
	font-weight: bold;
	margin: 5px 0 10px 0;
	color: @first-level-section-heading-color;
}

#issue{
	.pd-remove{
		padding: 0;
	}	
}

@media print {
	.non-printed-section {
		display: none !important;
	} 
}
