.video-item__video--disable-download::-internal-media-controls-download-button,
audio-item__audio--disable-download::-internal-media-controls-download-button {
    display:none;
}


.video-item__video--disable-download::-webkit-media-controls-enclosure,
.audio-item__audio--disable-download::-webkit-media-controls-enclosure
 {
    overflow:hidden;
}

.video-item__video--disable-download::-webkit-media-controls-panel
 {
    width: calc(100% + 5px); /* Adjust as needed */
}

.audio-item__audio--disable-download::-webkit-media-controls-panel {
    width: calc(100% + 10px); /* Adjust as needed */
}

.supplementary-container .mp4Item-options {
    display:none;
}

.supplementary-container h4 br {
 display: none;
}

.video-item__video{
    width: 100%
}

.video-item__video.disabled {
    opacity: 0.4;
}

@media (max-width: 540px) {
    video,audio {
        width:100%;
    }
    
    .icon-image-content 
    .dataandmedia.tab-pane {
        padding-left:0;
        padding-right: 0;
    }
    
    #supplementary_data .articleMetadata {
        padding-right:0;
    }
    
    .video-item__video--disable-download::-webkit-media-controls-panel{
        width: calc(100% + 10px); /* Adjust as needed */
    }
    
    
}
