#book,#chapter{
  .mostviewedloading{
    display: none;
  }  
}


.title-subtitle{
    margin-bottom: 18px; 
    h2{
        margin: 0;
        padding-bottom: 10px;  
    }
    h3{
        margin: 0;
    }
}

.chapterdata{
    list-style: outside none none;
    padding: 0;
    > li{
        padding: 10px 15px;
     &.chapter__section-group {
        + .chapter__no-section {
         margin-top: 20px;
        
         }
     }
        .browseItemTitle{
            margin: 0 0 10px 0;
            a{
                font-weight: @font-weight-browse-item-title;
                text-decoration: @font-decoration-browse-item-title;
            }
        }
        &.odd{
            background: @light-bg-color;
            border-top: 1px solid @dborder-color;
            border-bottom: 1px solid @dborder-color;
        }
    }
}

.highlight {
    background-color: @search-highlighted-text;
    color: @midnight-black;
    font-style: normal;
}

.image-box{
    vertical-align: top;
    max-width: 93px;
    margin: 0 10px 10px 0;
    float: left;
}

.propertie-box{
    margin-bottom: 15px; 
    .meta-key{
        font-weight: 600;
    }
}


.articleMetadata h5 {
    margin-bottom:3px;
        line-height: 1.3;   
        margin-top: 1px;
}

.browse-item {
    .articleTitle {
    font-weight: @font-weight-browse-item-title;
        a{
            text-decoration: @font-decoration-browse-item-title;
        }
        p {
            margin-bottom:0;
            display: inline;
        }  
    }
    h3, h4, h5 {
        font-size: @font-size-browse-item-title;
        line-height: @line-height-browse-item-title;
    }
    .sub-title {
        font-size: @font-size-base;
    }
}
.main-content-container > .nav-tabs {
    margin-top:15px;
}

.tabbedsection { 
    /* 
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd; */
    float: left;
    width:100%;
    .browse-table-box { 
         display:table;
         width: 100%;

        > div {
            display: table-cell;
            vertical-align: top; 
          
        }
        
         .journal-top-left img {
            margin: 15px;
            max-width:200px;
        }
        .journal-top-left .meta-block {
            margin: 15px;
            min-width: 120px;
        }
        .journal-top-right .journalOverviewDescription {
            margin: 15px;
        }
           
    } 

    
    .showOtherLatestArticles {
       float: left;
        clear: both;
        text-align: right;
        width: 100%;
    }
    
     .journal-left-box{
        float: left;
        max-width: 183px;
        box-sizing: border-box;
        padding: 0 15px;
        min-width: 175px;
    }

    .journal-right-box{
        box-sizing: border-box;
        padding: 0 15px;
        float: none;
        width: 100%;
    }
}

.pills-container {
    background-color: @nav-pills-inactive-bg-color;
    margin: 0 0 10px 0;
    width: 100%;
    float:left;
    padding: 0;
    li {
        > a, 
        > a:hover, 
        > a:focus {
           border-radius: 0;
           margin-bottom:0; //override bootstrap
           white-space: nowrap;
           color: @nav-pills-inactive-link-color;
           background-color: @nav-pills-inactive-bg-color;
        }
    }
    .nav-stacked{
        li.tab-menu{
            border-bottom: 2px solid @default-background-colour;
        }
    } 
    
}

.lessLink {
    display:none;
}

.extraitems { 
    overflow: hidden;
    width:100%;
    border: 1px solid #ccc;
    padding: 7px;
    margin: 22px 0 10px 0;
    display: table;
    .tools {
        display: table-cell;
        vertical-align: middle;
        .nav-pills {
            float: right;
            .link-style {
                padding: 10px 10px;
            }
        }
    }
    .pricingDetails {
        display: table-cell;
        vertical-align: middle;
        width: 150px;
        text-align: center;
        .discountPrice {
            display: block;
        }
        .access-options {
            margin: 8px 0;
            .buyItem, .pdfItem {
                 &:hover {
                    color: @default-background-colour;
                }
            }
            .carnetOptionDescription{
                margin-bottom: 3px; 
            }
        }
        .lineThrough {
            text-decoration: line-through;
        }
    }
}
/* reference css */
    
 .reference-surname, 
 .reference-collab,
 .reference-given-names,
 .reference-bold,
 .citationContainerArticleTitle {
     font-weight:bold;
 }
 
 .reference-etal {
     font-weight:bold;
      font-style:italic;
 }
 
 .reference-source,
 .reference-italic,
{
     font-style:italic;
 }
@media (max-width: @screen-md-max) and (min-width: @screen-sm-min) { 
.pills-container {
    .nav-pills{
            li {
                a{
                    font-size: 14px;
                    padding: 10px 0;
                }
            }
        } 
    }

    .volume-issue{
        display: block;
    }
}


@media screen and (min-width: @screen-md){
    .journaloverviewtab > .row{
        > * {
            display: table-cell;
            vertical-align: top;
          
        }
    }

    .text-right-access{
        text-align: right;
    }
}

@media screen and (max-width: @screen-md){
    .extraitems { 
        font-size: 12px;
    }
    hypothesis-sidebar {
        display:none;
    }
}
@media screen and (max-width: @screen-xs){
    .extraitems { 
        font-size: 14px;
        .tools {
            padding-top: 0;
            padding-bottom: 10px;
            .nav-pills {
                >li {
                    float: none;
                    display: inline-block;
                }
            }
        }
    }
}
@media print {
    .annotator-frame {
        display: none;
    }
}

.panel-title {
    font-size: @font-size-panel-title;
}

.errorpage{
    font-weight: unset;
    font-size: 14px;
}

.featured-content {
	text-transform: uppercase;
    font-weight: bold;
    display: block;
    color: @brand-secondary;
}

.viewItemCitation .viewItemCitationEntry {
   padding-bottom:20px;
}

.is-content-editor .publist-paginated > li.listingItem {
    border: 1px dashed grey;
    display: flex;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    cursor: move;
    transition: transform 0.4s ease-in-out;
    &.over {
        border: 1px dashed red;
    }
    &.dragging {
        border: 1px dashed green;
         > * {
         opacity: 0.5;
        }
    }
    &.moving {
        transform: translateY(5px);
    }
}

.is-content-editor .block-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .fa {
     pointer-events: none; 
    }
}

.is-content-editor .__shake {
	animation: shake .4s linear;
}


.is-content-editor li.listingItem > .meta-data-container {
    width:100%;
}

.is-content-editor .browse-item {
    margin-bottom: 0;
}