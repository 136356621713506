.table-custom {
	margin: 0;
	// Cells
	> thead,
	> tbody,
	> tfoot {
		> tr {
		  > th {
			vertical-align: middle;
			padding: @table-cell-padding - 3;
		  }
		  > td {
		    vertical-align: middle;
		    padding: @table-cell-padding;
		    white-space: normal;
		    >a {
		    	white-space: normal;
		    	max-width: 350px;
		    	min-width: 200px;
		    	display: inline-block;
		    	&:hover, &:focus {
    				text-decoration: none;
    			}
			    >p {
			    	margin: 0;
			    }
		    }
		  }
		  &:nth-of-type(2n+1) {
		  	background-color: #f2f2f2;
		  }
		}
	}
	> thead > tr > th {
	  	color: @brand-primary;
	    background-color: @default-background-colour;
	    vertical-align: middle;
	    border: none;
	}
	> thead > tr > td {
		font-size: 13px;
	}
	// Account for multiple tbody instances
	> tbody {
		border-top: 2px solid @table-border-color;
	}
	> tbody + tbody {
		border-top: 2px solid @table-border-color;
	}
    .caret {
    	display: inline-block;
    	vertical-align: middle;
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
    }
    .collapsed {
	    .caret {
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
	    }
    }
    .list-dropdown {
    	padding: 0;
		white-space: normal;
    	max-width: 260px;
		font-size: 13px;
		margin-top: 10px;
		list-style-type: none;
		margin-bottom: 5px;
    	box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
    	-webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
    	background: rgba(0,0,0,0.05);
    	li {
    		a {
	    		border-top: 1px solid rgba(0,0,0,0.1);
	    		padding: 7px 10px;
	    		margin-bottom: 0;
    			margin: 0;
    			display: block;
    			&:hover, &:focus {
    				text-decoration: none;
    			}
    			p {
    				margin-bottom: 0;
    			}
    		}
    		&:first-child {
    			a {
    				border: none;
    			}
    		}
    	}
    }


}

.accordian-custom {
	margin-bottom: @line-height-computed;
	> .panel {
		border-radius: 0;
		margin-top: 0;
		margin-bottom: 0;
		+ .panel {
	      margin-top: 0;
	    }
		> .panel-collapse {
			> .panel-body {
				padding: 0;
				margin-top: 5px;
				border: 2px solid @text-color;
			}
		}
		> .panel-heading {
			padding: 0;
			margin-top: 10px;
			border-radius: 0;
			.panel-title {
				display: block;
				padding: 0;
				>a {
					display: block;
					border: 1px solid @brand-primary;
					background: @brand-primary;
					color: @default-background-colour;
					padding: 15px;
					text-decoration: none;
					.fa-caret-down {
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						transform: rotate(180deg);
					}
					&.collapsed {
						background: none;
						color: @brand-primary;
						.fa-caret-down {
							-webkit-transform: rotate(0deg);
							-moz-transform: rotate(0deg);
							-ms-transform: rotate(0deg);
							transform: rotate(0deg);
						}
					}
				}
			}
		}
	}
}


