/* This file extends bootstraps responsive-utility */

// extending Visibility utilities
.visible-xxs {
	.responsive-invisibility();
}
.visible-xs-only {
  display: none;
}
 
.visible-xxs {
	  @media (max-width: @screen-xxs-max) {
    .responsive-visibility();
  }
}

.visible-xs-only {
  @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    .responsive-visibility();
  }
}



.hidden-xxs { 
  @media (max-width: @screen-xxs-max) {
    .responsive-invisibility();
  }
}


.hidden-xs-only {
  @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    .responsive-invisibility();
  }
}