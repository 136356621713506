.bookmark_popup_text {
    img {
        border: none;
        vertical-align: bottom;
    }
}
.bookmark_popup {
    display: none;
    position: absolute;
    z-index: 100;
    ul {
        float: left;
        width: 320px;
        border: 1px solid #aaa;
    }
}
.bookmark_list {
    display: inline-block;
    float: left;
    list-style-type: none;
    font-size: 80%;
    margin: 0px;
    padding: 0px;
    li {
        float: left;
        width: 23%;
        margin: 0px 1px;
        padding: 1px;
        background-color: #eee;
        border: 1px outset;
    }
    a {
        display: block;
        text-decoration: none;
        cursor: pointer;
        padding:0;
    }
    span {
        display: inline-block;
        width: 16px;
        height: 16px;
    }
    span.bookmark_icons {
        background: url("@{platform-img-path}bookmarks.png") no-repeat center;
    }
    img {
        border: none;
    }
}

html:first-child {
    .bookmark_list {
        font-size: 80%;
    }
}
.bookmark_compact {
    background-color: #eee;
    border: 1px outset;
    li {
        width: 16px;
        height: 16px;
        margin: 3px;
        padding: 0;
        background-color: transparent;
        border: none;
    }
}
#bookmark_all {
    display: none;
    position: @mobile-menu-position;
    z-index: 100;
    background: @default-background-colour;
    border: 2px solid #888;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    p {
        margin: 0em;
        padding: 0.5em;
    }
    ul {
        width: 24em;
        height: 20em;
        overflow: auto;
    }
    li {
        width: 48%;
        background-color: transparent;
        border: none;
    }
}



/** custom less to create dropdown */
.sharelinks {
    position:relative;
    .bookmark_compact {
        background: @navbar-default-bg;
        border: 1px solid @dborder-color;
        margin-top: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        li {
            height: auto;
            float: none;
            display: inline-block !important;
            width: auto;
            a {
                border: 1px solid @dborder-color;
                border-radius: 50%;
                display: inline-block;
                font-size: 16px;
                height: 40px;
                line-height: 40px;
                margin: 3px 6px;
                text-align: center;
                text-decoration: none;
                width: 40px;
                background: @default-background-colour;
                &:hover {
                    background: @dborder-color;
                }
            }
        }
    }
    .moreshareoptions {
        display: none;
        z-index: 1;
        height: 24px;
        left: -50px;
        position: absolute;
        top: 100%;
        width: 200px;
    }
}