#articlereference {
    padding-left: 26px;
    .refbody {
        padding: 10px 0;
        .citation{
        	display: inline;
        	a {     
        		word-break: break-all;
              }
        }
    }
}
.articlereference-apa {
   list-style: none;

   .mixed-citation-apa {
      text-indent: -26px;
   }
}

