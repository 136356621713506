    /* Styles for My Account area
	These could be moved out to more specific .less file eventually
	
	Alerts
	My Favourites
	General Styles
	Search History and Saved Searches
	
*/


#newsletterspage,
#currentalertspage,
#currentcontentalertspage
{
	.updateAlertsHeading {
	    margin-bottom:20px;
	    margin-top:20px;
	}
	
	.updateAlertsButton {
	    float:right;
	    margin:10px 50px;
	}
	
	.alertNameCol {
	    width:60%;
	}
	
	.alertActionCol {
	    width:20%;
	}
	
	.alertLinks {
		clear: both;
   		padding-top: 15px;
	}
	
	// Specific table styles to this area
	.alertsList
	{
		th, td {
			padding: 4px 10px 4px 5px;
		}
		
		input[type="radio"], 
		input[type="checkbox"]
		{
			margin:3px 3px 3px 4px;
		}
		
	}
}

#savedsearchespage { 
    .searchgo {
        margin:8px 0px 8px 0px;
        .savedSearchGo.btn-default {
            margin-left:10px;
            }
        }
     .itemLinks a {
         text-decoration: underline;
     }
    
        .showsearchdetailsWrapper {
            clear: both;
            padding-left: 2%;
        }
        .folderChangerPopup {
            display:none;
            background-color: @default-background-colour;
            border: 1px solid @gray;
            padding: 8px;
            position: absolute;
            text-align: left;
            width: 335px !important;
            z-index: 9;
            .fright{
                float:right;
            }
    }
    .savedSearchHeadings {
        clear: both;
        display:flex;
        padding-bottom:0;
        > div {
            display: flex; 
            justify-content: flex-end; 
            flex-direction: column;
            line-height: 1.2;
            word-break: break-word;
            @media only screen and (max-width: 767px) {
                font-size:12px;
            }

        }
     }
     .savedSearchFolders {
        clear: both;
     }
     
    .createNewFolderButton {
        text-decoration: underline;
     } 
}
@media only screen and (max-width: 767px) {
	#newsletterspage,
	#currentcontentalertspage
	{
		.updateAlertsButton .styledbutton {
		  width:100%;
		  margin:10px 0;
		}
		
		.updateAlertsButton {
		  margin:10px 0;
		}
	
	}
}


/* My Favorites 

	Some of the styles here have been taken from the old rummage.css - some styles appear to
	have already been copied over to advancedsearch.less but not all.  
	Additionally, those within _advancedsearch.less are for the specific page.
	We need to be careful about duplication. Styles may need condensing.
*/

#markedlistspage{
    /* for new reader button */
    .btn.btn-default.mg-t-5 {
        margin-top: 0;
    }
	.manageMarkedList {
	    clear:both;
	    margin-bottom: 14px;
	    .sortBy{
			padding-top:5px;
	    }
	
	    .sortByInputLabel{
	 		 float:left;
	 		 margin-right:20px;
	 		 margin-top:3px;
	 		 
	 		 label
	 		 {
	 		 	font-weight:bold; 
	 		 }
	    }		
		.lessdisplayPerPage {
		    float:right;
		    /* margin-top:-6px; */
		   @media only screen and  (max-width: @screen-xs-max) {
		    	form {
					width:100px;
				}
		    }
		}
		
	 	.resultItem{
		    border-bottom:solid 1px @gray;
		    float:left;
		    margin:0 0 19px 0;
		    padding-bottom:10px;
		    padding-left:0;
		    width:100%;
		    	     	
			.formControl{
				float:left;
				width:20px;
			}
			.title{
				font-weight:bold;
				a{
					p{
						// Some instances the title is within a p which will throw the styles out so make this inline
						display:inline;
					}
				}
				.sup{
					vertical-align:top;
				}
			}
	
			.searchTerm{
				background-color:yellow;
			}
			
			.authors {
				.label{
					font-weight:bold
				}
				.sup{
					vertical-align:top;
				}
			}
		
			.source {
				.label{
					font-weight:bold
				}
			}
			
			.editor {
				.label{
					font-weight:bold
				}
			}
			.publicationYear{ 
				.label{
					font-weight:bold;
				}
			}
			
			.minus {
				display: none;
			}
			
			.showhide {
				cursor: pointer;
			}
				
			.pubType {
				float:left;
			    width: 25px;
			    word-wrap: break-word;
			}    	
	     	.controlbookmarks {
			    	#share-nav {
			    		width:auto;
			    		margin-right:0px;
			    		&.collapse{
			    			display:block;
			    			padding-left:0px;
			    			padding-right:0px;
			    			margin-bottom:0px;
			    			margin-top: 10px; 
			    		}
			    	}
			    	.panel-heading {
					    display: inline-block;
					    border-bottom:none;
					}
			    	.panel-body {
					    padding: 7px;
					    display: inline-block;
					}
			}
	 		.itemDescription {
			    float:left;
			    margin-left:0;
			    padding-right: 10px;
			    max-width: 368px; /* ensure this resizes on mobile */
	 		}
	 		
			
			/* Social Media Icon link positioning on my account / favourites page */
		
			.bookmarkcontainer {
			    clear:right;
			    float:right;
			    margin-top:10px;
			    
			    .sharelinks {
					  cursor: pointer;
					  display: inline-block;
					  padding: 0 0 0 2px;
					  position: relative;
					}
			}
			
			.formControl {
			    clear:left;
			    margin-top:10px;
			    
			    .left {
				    float:left;
				    margin:0 5px 0 0;
				}
			}			     	
	 	}

	 	.download-pdf{
			float: left;
			margin: 0 9px 0 0;
	 	}

	 	.pagination{
	 		margin: 0;
	 	}

	}
	
	#lessupdatemarkedlist,
	#updatemarkedlist {
	    border-bottom:solid 1px @gray;
	    border-top:solid 3px @gray;
	    margin:0 0 19px 0;
	    padding:9px 0;
	}
	
	.removeMyFavourite {
	    display:block;
	    font-size:80%;
	    margin:0 0 0 16px;
	    width:50px;
	}
	.myFavouritesResults {
	    font-weight:bold;
	}
	
	@media only screen and (max-width: @screen-xs-max) {
		.manageMarkedList .resultItem .pubType {
			float:none;
			width:100%;
		}
		.access-options_outer {
    		margin: 15px 0px;
		}
	}

}
/* Other General Styles */



/*For Administration layout's border and spacing*/ 
.layoutrow {
    border: 1px solid @gray;
    padding-top: 20px;
    margin-bottom: 30px;
    .token {
        margin-bottom: 20px;
    }
}




// Changes to tabs media query so the titles of the tabs display correctly in the tabs on mobile view (search history and saved searches)



/* Content alerts */
#newsletterspage,
#objectapertspage, #currentalertspage {
    .updateAlertsHeading {
        margin:20px 0px;
    }
	td.alertActionCol {
	    word-break: break-all;
	}
/* 
    .a_to_z_mobile {
        font-family: @font-family-sans-serif;
        float: right;
        margin-right:10px;
        @media (min-width: 768px) {
            display: none;
        }
    }

    .paginator {
        display: inline-block;
        font-size: 9.5px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 1em;
    }
    * 
    */
    .alertsList {
        padding-bottom:15px;
        .alertsListTable {
            tr {
                th {
                    padding:4px 10px 4px 5px;
                    font-weight: bold;
                    &.alertNameCol {
                        width: 50%;
                        opacity: 0;
                    }
                    &.alertActionCol {
                        width: 25%;
                    }
                }
                td {
                    padding:4px 10px 4px 5px;
                }
            }
        }
    }
    .updateAlertsButton {
        padding:4px 0px;
        clear: both;
        a.styledbutton {
            float: right;
        }
    }
} //top level


/* Manage alert format */
#newsletterspage,
#alertformatpage {
    #formatform {
        fieldset {
            @media (max-width: 767px) {
                width: 100%;
            }
            width: 85%;
            float: left;
            padding:14px;
            margin:px 0px 15px 0px;
            border: 1px solid @input-border;
            .alertingTo {
                width: 100px;
                display: inline-block;
            }
            label[for="alertingFormat"] {
                width: 96px;
                display: inline-block;
            }
            #alertingFormatHTML {
                margin-left:20px;
            }
        }
        .updateAlertsButton {
            @media (max-width: 767px) {
                width: 100%
            }
            width: 85%;
            clear: both;
            padding:4px 0px;
            a {
                float: right;
                margin:10px 0px;
                min-width: 120px;
            }
        }
    }
    .alertMobileDisplay {
        @media (max-width: 480px) {
            display: block;
            margin-left: 79px;
        }
    }
} //top level


