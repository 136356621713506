// accesskey: A key to various types of access for the content available
.accesskey {
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    margin-top: 30px;
}

.accesskey-list {
    margin-bottom: 10px;
}

.accesskey-heading {
    margin-top: 7px;
}

.accesskey-item {
    border: 0;
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
}

.accesskey-icon {
    background-color: @link-color;
    color: white;
    font-size: 80%;
    width: 2em;
    display: inline-block;
    text-align: center;
    margin-right:4px;
}


@media(max-width:479px){
    .icon-key{
        ul{
            li{
                width:100%; 
            }
        }
    }
}