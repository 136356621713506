.fa-stack-1x{
    margin-top: 4px;
}

.icon-image {
    background: @navbar-default-bg;
    border: 1px solid @dborder-color;
    display: inline-block !important;
    min-height: 42px;
    margin-left: 16px;
    padding-top: 4px;
    margin-bottom: -15px;
    position: relative;
    overflow: hidden;
    ul {
        &.tabset {
            margin: 0;
            position: static;
            margin: 0;
            li{
                width: auto;
                border-bottom: none;
                display: inline;
                vertical-align: bottom;
                a {
                    padding: 7px !important;
                    width: auto !important;
                    border-radius: 0;
                    -webkit-border-radius: 0;
                    text-align: center;
                    min-height: 70px;
                    min-width: 90px;
                    .iconContainer {
                        > i {
                            font-size: 2em;
                            &.fa-lg {
                                font-size: 1em;
                            }
                        }
                        .titleIcon {
                            white-space: nowrap;
                            display: block;
                            font-size: 12px;
                            line-height: 13px;
                            margin: 10px 0 0;
                            font-weight: @item-home-page-tab-text-weight;
                        }
                    }
                    &.active{
                        border: none;
                        outline: none;
                        border-bottom: 3px solid @tabs-icon-fonticon-border-color;
                        color: @tabs-icon-fonticon-color;
                    }
                    &.focus{
                        border: none;
                        outline: none;
                    }
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

.icon-image-content {
    .tab-pane {
        word-wrap: break-word;
        -moz-word-wrap: break-word;
        &.citations {
            ol {
                padding-left: 25px;
                list-style: initial;
                list-style-type: decimal;
                li {
                    margin-top: 20px;
                }
            }
        }
    }
}

.tabs > .active {
    display: block !important;
    min-height: auto !important;
    margin-bottom: 25px;
}

@media (max-width: @responsive-tabs-icon-breakpoint) {
    .icon-image {
        width: auto;
        margin-right: 15px;
         ul {
            &.tabset {
                width:100%;
                margin:0;
                position:static;
                li{
                    display: inline;
                    vertical-align: bottom;
                    a {
                        top: auto;
                        position: static;
                        vertical-align: bottom;
                        min-height: 90px;
                        display: inline-block !important;
                        .iconContainer {
                            .titleIcon {
                                display: block;
                                margin-top: 5px;
                            }
                        }
                    }
                }
                &:after {
                    display: none;
                }
            }
            &.open {
                a {
                    background: none;
                }
            }
        }
    }
}


@media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
    .icon-image {
         ul {
            &.tabset {
                li{
                    a {
                        width: 7.5vw !important;
                    }
                }
            }
        }
    }
}


@media (max-width: @screen-md-min) and (min-width: @responsive-tabs-icon-breakpoint + 1 ) {
    .icon-image {
         ul {
            &.tabset {
                li{
                    a {
                        width: 10.5vw !important;
                    }
                }
            }
        }
    }
}

@media (max-width: @screen-md-max) and (min-width: @responsive-tabs-icon-breakpoint + 1 ) {
    /* PLAT-530 elipsis tab long titles when looking on a tablet  */
    .icon-image .tabIcon .titleIcon{
            overflow: hidden;
            text-overflow: ellipsis;
    }
} 


@media (min-width: (@responsive-tabs-icon-breakpoint + 1)) {
   .icon-image ul.tab-dropdown .select {
        display:none;
    }
    .icon-image {
        ul{
             &.tabset{
                li{
                    display: inline-block !important;
                }
             } 
        }
    }           
}

@media (max-width: @responsive-tabs-icon-breakpoint) {
    .icon-image {
        padding-top: 0;
        display: block !important;
         ul {
            &.tabset {
                text-align: center;
                li {
                    a {
                        min-height: 45px !important;
                        width: 65px !important;
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                        .iconContainer {
                            > i {
                                height: 1em;
                                width: 1em;
                                font-size: 2em;
                                &.fa-lg {
                                    height: 2em;
                                    width: 2em;
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }
            }
            &.tab-dropdown{
                text-align: left;
                li{
                    display: none;
                    width: 100% !important;
                    a{
                        text-align: left;
                        width: 100% !important;
                        min-height: auto;
                        border-bottom: 1px solid @dborder-color;
                        cursor: pointer;
                        padding-right: 17px;
                        padding-left: 17px;
                        .iconContainer{
                            i{
                                float: left;
                                margin-right: 7px;
                            }
                            .titleIcon{
                                margin-bottom: 5px;
                                font-size: 14px;
                                font-weight: normal;
                            }
                        }
                        &.active{
                            border-bottom: 1px solid @dborder-color !important;
                        }
                    }
                }
                &:after{
                    color: @brand-secondary;
                    display: block;
                    font-size: 20px;
                    top: 5px;
                    pointer-events: none;

                }
                .select{
                    border-bottom: 3px solid @brand-tertiary;
                    .mobile-tab{
                        padding: 12px 7px;
                        cursor: pointer;
                        color: @brand-tertiary;
                    }
                }
            }
        }
    }
}

