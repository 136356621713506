
/* Admin home page  - Profiles - Content - Reports */

#adminpage, #myaccountpage {
	.layoutrow:nth-child(1) {
	    margin-top:43px;
	}
	.layoutrow.col-xs-12.col-sm-12.col-md-12 {
	    border: 1px solid @grey-light-custom;
	    margin-bottom: 22px;
	    padding-top: 22px;
	}
}

#accountmanagementpage{
	 fieldset {
    border: 1px solid @gray-light;
    margin: 0 0 15px;
    padding: 1.4rem;
    }
    #useradmincontent input, #useradmincontent select {
	    margin-bottom: 0.8rem;
	}
	 #search_scope {
	    border: 1px solid #ccc;
	    border-radius: 3px;
	    color: #555555;
	    display: inline-block;
	    font-size: 13px;
	    line-height: 18px;
	    padding: 4px;
	}
	#useradmincontent .searchresults {
		width:100%;
	}
	#useradmincontent th, #useradmincontent td {
    	padding: 4px 10px 4px 5px;
	}
}
#user {
	#tabsetContainer {
		.disabled_access {
			color: @gray-light;
		}
		fieldset{
    		margin: 14px 0;
		}
		table {
			width:100%;
		}
	}
}

#trackdetails {
	.regInput {
	    margin: 8px 0;
	    input.checkbox {
		    float: left;
		    padding-right: 10px;
		    width: 34px;
		}
	}
}
#ipmanagementpage {
	.ipmanagement {
	    margin-top:25px;
	}
}

/* popup to sign in to action events, IE add to favourites */
.signInOrRegisterWrapper {
	.ui-dialog .ui-dialog-titlebar {
	    font-size: 0;
	    padding: 0;
	}
	.ui-dialog .ui-dialog-titlebar-close {
	    margin: -13px 0 0;
    }
	h2{
	    margin-top: 0;
	}
}

.searchresults {
	margin: 10px -5px;
	width: 100%;
	border: 1px solid #dedede;
	border-bottom: none;
	thead {
		th {
			padding: 10px;
			background: #dedede;
		}
	}
	tbody {
		tr {
			&:hover {
				background: #f2f2f2;
			}
		}
		td {
			padding: 7px 10px;
			text-decoration: none !important;
			border-bottom: 1px solid #dedede;
		}
	}
	.radio, .checkbox {
		display: table-cell;
		margin: 0;
		input[type="radio"], input[type="radio"], input[type="checkbox"], input[type="checkbox"] {
			text-align: center;
			margin: 0;
			position: static;
			vertical-align: middle;
		}
	}
}
