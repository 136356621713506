/*
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Autocomplete 1.10.3
 * http://docs.jquery.com/UI/Autocomplete#theming
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */
.ui-autocomplete {
	position: absolute;
	top: 0;
	left: 0;
	cursor: default;
}