.sidebar-pub2web-element  {
    #morelikethis .articleSourceTag {
        display:none;
    }
    #morelikethis .showhide {
        display:none;
    }
	.trendmd-widget {
    	padding-top: 0px !important;
    	.trendmd-widget-header {
    		display: none;
    	}
    }
}

#figureContainer {
    height: 100%;
    .imgContainer {
        height: 100%;
        img {
            max-height: 100%;
            display: block;
            margin: 0 auto;
        }
    }
}
.pager {
    margin: 0 0 10px 0;
}
    .lb-outerContainer{

        .lb-image{
            border-radius: 0 !important;
            border: none !important;
        }

        .lb-nav a{
            &.lb-prev{
                margin-left: -80px;
                opacity: 1;
            }
            &.lb-next{
                margin-right: -80px;
                opacity: 1;
            }   
        }
    }
    
    
.lightboxOverlay{
    opacity: 0.6 !important;
} 

.lightbox{
    position: fixed !important;
    top: 52px !important; 
}

 .lightboxheader{
        width: 100%;
        padding: 17px 0;
        color: @default-background-colour;
        position: fixed;
        top: 0;
        background: #1f1d1d;
        box-shadow: none;
        text-align: right;
        padding-right:20px;
        z-index: 8090;
        ul{
            margin: 0;
            padding-left:15px;
        
        li{
            display: inline-block;
            vertical-align: middle;
            a{
                color: #ffffff;
                margin-left: 8px;
                font-size: 15px;
            }
        
            span{
                margin: 0 8px;
                font-size:21px;
                cursor: pointer;
            }
        }
        }
    }
    
    .lightboxfooter{
        width: 100%;
        padding: 17px 0;
        color: @default-background-colour;
        position: fixed;
        bottom: 0;
        background: #1f1d1d;
        box-shadow: none;
        font-size: 15px;
        line-height: 1;
        z-index: 9999;
        .cover{
        max-width: 1135px;
        margin: auto;
        width: 90%;
        .showhide{
            display:inline;
            text-align: left;
            font-size: 20px;
            cursor: pointer;
        }
        
        p, .tl-default{
            margin: 15px 0 0 0;
            text-align: left;
            line-height:22px;
            color: @default-background-colour;
        }
        .js-tgl{
            display:none;
        }
        }
    }



ul.navigation{
    float: left;
    padding: 0;
    li{
        a{
        display: block;
            .back{
                background-image: url(images/fancybox_sprite.png);
                background-position: 0 -38px;
                display: block;
                height: 29px;
                width: 31px;
                background-repeat: no-repeat;
                margin: 0;
            }
            .next{
                background-image: url(images/fancybox_sprite.png);
                background-position: -2px -74px;
                display: block;
                height: 29px;
                margin: 0;
                width: 31px;
                background-repeat: no-repeat;
            }
        }
    }
}


.table-metadata{
    th{
        width: 180px;
    }
    &.table-bottom-m{
        margin: 0;
    }
    li {
        margin: 5px 0;
    }
}

.searchresultlink {
    float: left;
    padding-top: 10px;
}

.articleabstract h3 {
    display:none;
}

table.dataTable, h2#dailyMetrics {
    display:none;
}
//no longer make the assumption that fulltext item is a PDF!
.access-options {
    min-height: 40px; //this is needed where multiple resource is associated with one supp item
    a {
       background: transparent;
         &.disabled {
             cursor: @cursor-disabled;
             color: @nav-disabled-link-color;
         }

        i{
            float: right;
            font-size: 19px;
            text-decoration: none;
        }
   }
   

 }


 .article-cover{
    position: relative;
    padding-bottom: 10px;
    .col-sm-10 {
        position: static;
    }

    ul{
        li{
            span{
                &.underline{
                    &:hover{
                        cursor: pointer;
                    } 
                }
            }
        }
    }

     .mob-right-gap{
        img.cover{
            width: 114px;
            padding-bottom:10px; 
        }
    }
 }

 .title-box{
    h2{
        font-size: @font-size-content-item-title;
        line-height: @font-line-height-content-item-title;
        margin: -4px 0 9px;
        a{
            color: #213368;
            text-decoration: underline;
        }
    }
}
.title-box{
    .h2{
        font-size: @font-size-content-item-title;
        line-height: @font-line-height-content-item-title;
        margin: -4px 0 9px;
        a{
            color: #213368;
            text-decoration: underline;
        }
    }

    .h3{
        margin: 0 0 8px 0;
        font-size: 17px;
    }
    abbr {
        font-weight: bold;
    }
}

.bottom-side-nav {
    margin: 0 0 30px;
    .pagernav{
        margin: 0 0 15px 0;
    }
}

.pagernav{
    list-style: none;
    padding: 0;
    overflow: hidden;
    margin: 15px 0 0;
    li{
        float: left;
        & + li{
            &:before{
                color: gray-secondary;
                content: "|";
                padding: 0.8rem;
            }
        }
    }
}


.spacing{
    margin: 2px 0;
    .affiliation-details{

        .affiliations {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 5px;
        }
        margin: 3px 15px 10px;
        .affiliations_label {
            display: block;
            margin: 5px 0;
        }
        .affiliations_number {
            position: relative;
            top: -0.3em;
        }
        a {
            display: inline;
        }
    }
    span{
        i{
            color: gray-secondary; 
        }
    }
}


.mg-b-20{
    margin-bottom: 20px;
}

.mg-t-20{
    margin-top: 20px;
}

.right-title-box {
    float: right;
    padding: 10px 5px;
    width: 173px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    text-align: center;
    .lineThrough {
       text-decoration: line-through;
    }
    .buyItem {
        margin-top: 5px;
        .lineThrough {
           text-decoration: line-through;  
        }
        &:hover {
            color: @default-background-colour;
        }
    }

    .discountPrice {
        margin-top: 0;
        display: block;
        margin-bottom: 5px;
    }
}

.is-blurb-enabled .right-title-box {
    //this is a fix for PLAT-5517 feedback!
    min-width: 90px;
}
.htmlfulltext {
    .itemFullTextHtml {
        ol{
            &.references{
                padding-left: 26px;
                li{
                    list-style-type: decimal;
                    padding: 6px 0;
                    .refcontainer{
                       display: inline;
                    }
                }
            }  
        }
    }
}

.pills-margin-bottom{
    margin-bottom: 20px;
}


/*Commenting section */ 

.commentsContainerEnabled{
    margin-top: 50px;
    .commentsContainer {
      border-top: 3px solid @dborder-color; 
      padding: 12px 0; 
        h3{
            margin: 0;
        }
    }
    
    .commentBlock{
        padding: 15px 0;
        border-bottom: 2px dotted @dborder-color;
       .postDetails{
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 6px;
       }
        &:last-child{
            border-bottom: none; 
        }
    }
}

#comments{
   .commentsContainerEnabled{
    margin-top: 0;
   } 
}


#addCommentForm {
    textarea {
        height: 278px;
        margin-bottom: 10px;
        width: 100%;
    }
}

#runtimeP2wRecaptcha__commenting_addcomment{
    .checkboxlabel{
        float: left;
    }

    #timer_id{
        float: left;
        margin: 2px 5px;
    }
}

/*Commenting section */

@media (min-width: @screen-tablet){
    .fancybox-nav {
        span{
            visibility: visible !important; 
        }
    }
}

@media (max-width: @screen-tablet){
    .fancybox-nav {
        visibility: hidden; 
    }
}

@media (max-width: @screen-sm-max){
    .article-cover{
        .title-box{
           margin-bottom: 14px;
        }
    }

    .pagernav{
       margin-bottom: 15px; 
    }
}


@media (max-width: @screen-xs-max){
    .article-cover{
        padding-left: 0;
        padding-right: 0;
        .mob-right-gap {
            padding-right: 185px;
        }
    }
    .right-title-box {
        position: absolute;
        right: 0;
        top: 0;
    }
    
    .lightboxheader{
    padding-right: 4px;
        ul{
            li{
                 a{
                    font-size: 13px;
                    margin-left: 5px;
                 }
            }
        }
    }
}
.abstract.tabbedsection {
    font-size: @font-size-abstract;
}

.itemFullTextHtml #article-level-0-front-and-body, 
.itemFullTextHtml #article-level-0-back {
        font-size: @font-size-fulltext;
        line-height: @font-line-height-fulltext;
}

.article-title .article-title__main-heading {
    margin-top: 0;
    margin-bottom:20px;
}
.article-type, .article-access {
    display: inline-block;
}
.article-type {
    padding-right: 5px;
    font-weight: bold;
    font-size: 14px;
    color:#213467;
}

.article-access {
    font-size: 14px;
    padding:  0 7px;
    border-left: 1px solid #a59ca8;
    line-height: 10px;
    font-weight: bold;
}

.article-access ~ img {
    padding: 0 5px 0 0;
}

.article-meta-data .article-meta-data__item{
    padding: 10px 0;
}

.meta-container {
    display: inline-block;
    padding: 0 5px 0 0;
    &.meta-container--doi {
        font-weight:700;
    }
    
}

.meta-container + .meta-container  {
    padding: 0 10px;
    border-left: 1px solid #a59ca8;
    line-height: 15px;
}

    

.article-meta-data {
    font-size: 16px;
    .item-meta-data__author {
        padding-bottom:0;
    }
    
    .item-meta-data__author-affiliations {
      padding-top:0;
    }
}

.navbar-nav .fulltext-download-link {
    padding-bottom: 8px;
    .fulltext-download-link__thumb-image {
        height: 28px;
        margin-top: -5px;
    }
}

.item-meta-data {
    .author-label {
        display:none;
    }
    .item-meta-data__journal {
        display: inline;
        font-size: @font-size-journal-title;
        text-transform: uppercase;
        margin: 0;
        }
    .item-meta-data__journal-issue {
            display: inline;
            font-size: 15px;
            /*margin-left: 10px;*/
            vertical-align: bottom;
            line-height: @line-height-journal-title;
    }
    
    .item-meta-data__item-title {
        margin-top:0;
        display: inline-block;
    }
    .item-meta-data__journal-title-link:hover, 
    .item-meta-data__issue-title-link:hover {
        text-decoration: none;
    }
}

.article-cover {
    padding-bottom:0;
}


.item-meta-data__item-title .content__keyicon {
    padding-bottom: 3px;
    height: 24px;
} 
 /* this is for the new article navigation bar */
 
 
.article-navigation-bar .iconContainer {

    strong {
        font-weight: normal;     
        }
    i {
    display: none; //hide the tab icons!
    }
}

.is-altlayout-enabled .tab-pane {
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding-left: 0;

}

@media (min-width:@screen-md) {
    .article-navigation-bar {
     float:left;
     width: calc(100% - 10px);
     position: sticky;
     top: 93px;
     left: 0;
     z-index: 1;
     background-color: @default-background-colour;
    &.is-article-navigation-bar--fixed {
      border: 1px solid @grey-light-custom;
      box-shadow: 0px 2px 4px @grey-light-custom;
    }
  }
}


.dropdown-menu .top-section-link {
    display:none;
}

.article-navigation-bar {
    .dropdown-menu {
        z-index: 2;
        &.dropdown-menu--add-this {
            min-width: 160px;
            width:auto;
        }
        li {
            //add some padding for fingers
            padding: 3px 0;
        }
        .panel {
            margin-bottom: 0;
        }
        .panel-heading {
            display:none;
        }
        .panel-body {
            padding:0px;
        }
    }
    #toolsDropdown + .dropdown-menu {
        width: 220px;
    }
    
    .access-options {
        min-height: auto;
     }
     .ft-download-content__form {
        padding: 5px 10px 6px;
        text-align: center;
        img {
            height: 28px;
        }
        i {
            font-size: 24px;
        }
        i + span {
            vertical-align: text-bottom;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            background-color: @nav-link-hover-bg;
        }
        .blurb-editor img {
            height: initial;
        }
         button {
            margin: -5px; //Make button fit in with icons.
        }
    }
   
        // this is for when logged on as administrator 
    .download-pdf,
    .js-launch-price-overlay {
        display: inline-block;
        vertical-align: top;
    }
    
    .tools-nav {
           a {
            padding: 3px 10px;
            width: 100%;
            display: block;
            }
           .list-group {
                margin-bottom: 0;
            } 
            
    }
    
    .navbar-nav__item--share .dropdown-menu {
        overflow:visible;
    }

}

 .item-meta-data__item-title {
    .version-label {
        background-color: #018f4e;
        @media (min-width: @screen-sm-min) {
            padding: 4px 2px;
        }
        color: white;
    }
 }


 .review-status-key {
    margin: 10px 0; 
    border: 1px solid #ccc; 
    padding: 3px;
    .review-status-key__icon {
        padding: 0 4px;
    }
    .review-status-key__info-icon {
        color: rgb(128, 128, 128);
        float: right;
    }
 }

 .c-block-tip {
    float: right;
    position:relative;
    .c-block-tip__icon {
        cursor:pointer;
    }
    .c-block-tip__content {
        position: absolute;
        right: 2px;
        margin-right: 0;
        margin-top: 5px;
        top: 100%;
        width: 256px;
        padding:12px;
        font-size: 14px;
        line-height: 20px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0, 0.14),
                    0 3px 4px 0 rgba(0,0,0, 0.12),
                    0 1px 5px 0 rgba(0,0,0, 0.2);
        color: rgba(0,0,0,.54);
        background-color: #fff;
        z-index:4;
        &::before {
            box-sizing: border-box;
            background: 0 0;
            content: " ";
            display: block;
            height: 100%;
            left: 100%;
            position: absolute;
            top: -3px;
            width: 12px;
        }
        &::after {
            box-shadow: 1px -2px 2px 0 rgb(0 0 0 / 10%);
            margin-right: 0;
            content: " ";
            margin-top: -4px;
            position: absolute;
            right: 7px;
            top: 0;
            background-color: #fff;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg);
        }
    }
 }


 .review-status-main-msg {
     margin: 0;
 }

 .c-report-timeline {
     margin-bottom: 5px; /* overide bootstrap */
 }

 .c-report-timeline .material-icons {
    display: block;
  }
  .c-report-timeline .fa-long-arrow-up {
    display: block;
    padding-left: 10px;
    margin-bottom: -10px;
  }
  .c-report-timeline__report {
    position: relative;
  }
  .c-report-timeline__report .fa-long-arrow-up {
    position: absolute;
    top: 0;
    color: gray;
    left: 7px;
  }
  .c-report-timeline { 
     .c-report-timeline__headings-version {
         text-align:center;
         font-weight: bold;
     }
    .c-report-timeline__report {
         text-align:center;
       }
    .c-report-timeline__row:last-child th,
    .c-report-timeline__row:last-child td {
         border-bottom: 1px solid @gray;
     }
  }

  .p-article__color--dark {
    font-weight: normal;
  }

  .item-preprint-message {
    padding-bottom: 10px;
    margin-top: -5px;
      p {
        padding: 15px;
        font-size: 16px;
        &.bg-warning {
            background-color: #d7f2fd;
        }
        &.bg-info {
            background-color: #ffe6f2;
        }
      }
  }

  #review-status-breakdown.carousel {
    .article-meta-data {
        font-size: 14px;
        padding-top:10px;
    }
    .item-meta-data__doi {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .article-meta-data__item {
        padding: 5px 0; 
    }
//    .item {
//       min-height: 426px;
//    }
  }





 .complete-icon {
    color: green;
  }
  .failed-icon {
    color: #cb2d2d;
  }
  .unsure-icon {
    color: #213368;
  }
  .reporttool-icon {
    color: #213368;
  }


@media (max-width:@screen-sm-max) {
    //see priya's comments she wants this change to only apply to the hybrid skin for time being
    // https://jira.ingenta.com/jira/browse/PLAT-5653?focusedCommentId=816373&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-816373
   
     .navbar-nav--article-nav-right > li {
         float:left; //override bootstrap collapse behaviour. 
     }
     
      .navbar-nav--article-nav-left > li {
          float:left;
      }
     
      .navbar-nav--article-nav-left,
      .navbar-nav--article-nav-right {
          float:left;
          margin:0;
      }
      .article-navigation-bar {
            .open {
                background-color: @default-background-colour;
            }
            .navbar-nav--article-nav-right .open .dropdown-menu {
             left: unset;
             right: -38px;
            }
            .sharelinks .moreshareoptions {
             left: -135px;
            }
            
            .navbar-nav__item--share.open .dropdown-menu--add-this {
                right:0;
            }
            //these are for instance skin overide header css mess.
            .navbar-nav .material-icons {
                display:inline; //from hidden
            }
            .navbar-nav li.dropdown a {
                &:after {
                content: none;
                }
            }
            .navbar-nav li > a {
                color: @link-color;
                background-color: @default-background-colour;
                padding: 10px;
            }
            .navbar-nav .open .dropdown-menu {
             //reapply bootstrap standard styling
             position: absolute;
            }
            .navbar-nav .dropdown-menu {
                border: 1px solid rgba(0, 0, 0, 0.15);
                background-color: @default-background-colour;
            }
      }
     // This is add temporarily while there is a different requirments 
     // for mobile behaviour desktop for instance skin vs hybrid skin. 
    .is-hybrid-skin-template {
      .article-navigation-bar {
         position: fixed;
         top: 54px;
         left:0;
         width:100%;
         background-color: @article-navbar-mobile-background-colour;
         z-index: 1;

         .sharelinks .bookmark_compact {
             color: @article-navbar-mobile-link-colour;
             background-color: @article-navbar-mobile-background-colour; 
         }
         
         .navbar-nav--article-nav-right .dropdown + .dropdown.open .dropdown-menu {
             right: 0px;
         }

         /* this is for enabling the price inline. */
         .purchaseItem {
             padding-top: 5px;
             padding-bottom: 5px;
             color: @article-navbar-mobile-link-colour;
             .buyItem:hover {
                 color: @article-navbar-mobile-link-colour;
             }
             .regular-price {
                 color: @article-navbar-mobile-link-colour;
             }           
         }
         .open {
             background-color: @article-navbar-mobile-background-colour; 
         }
         .open .dropdown-menu {
             //reapply bootstrap standard styling
             position: absolute;
             //margin: 2px 0 0;
             background-color:  @article-navbar-mobile-background-colour;
             border: 1px solid #ccc;
             box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
             z-index: 10;
             max-height: calc(100vh - 114px);
             overflow-y: auto;
         }
         
         
         .navbar-nav__item .ft-download-content__form {
             //bring form in line with anchor link nav bootstrap standard
             padding-top: 10px;
            padding-bottom: 10px;
            &:focus .hidden-xxs, 
            &:hover .hidden-xxs {
                color: @article-navbar-mobile-background-colour;
            }
         }
     
         .navbar-nav__item a {
             color: @article-navbar-mobile-link-colour;
             background-color: @article-navbar-mobile-background-colour;
             line-height: 24px;
         }
         
         .navbar-nav__item.open a {
             background-color: @article-navbar-mobile-background-colour;
             border-color: #333;
         }
         
         .navbar-nav__item .access-options-icon + span { 
                 color: @article-navbar-mobile-link-colour;
         }
         
         .navbar-nav__item .fa-file-pdf-o  {
             color: #ec5353; //reduce saturation by 25%
         }
         
         .navbar-nav__item:not(.disabled) a:focus,
         .navbar-nav__item:not(.disabled) a:hover,
         .navbar-nav__item:not(.disabled) .access-options-icon:hover,
         .navbar-nav__item:not(.disabled) .access-options-icon:focus {
            color: @article-navbar-mobile-background-colour;
            background-color: @article-navbar-mobile-link-colour;
         }
         
         .navbar-nav__item.disabled a {
             color: hsl(0, 0%, 50%);
             pointer-events: none;
         }
         .dropdown.navbar-nav__item .dropdown-menu a {
             color: @article-navbar-mobile-link-colour;
             background-color: @article-navbar-mobile-background-colour;
             border-color: @default-background-colour;
             overflow: hidden;
             display: block;
             max-width: 89vw;
             text-overflow: ellipsis;
         }
         .navbar-nav .dropdown-menu .disabled > a {
             background-color:  @article-navbar-mobile-background-colour; 
             color: hsl(0, 0%, 50%);
         }
         .open .dropdown-menu {
             background-color:  @article-navbar-mobile-background-colour;
         }
         
     
     }
      .tab-pane {
          border-top: none;
      }
      

      
      .data-author .author-label {
          display: none;
      }
      
      .articleabstract h3 {
            margin-top: 0;
       }
       
     }

    
    
 }


      
