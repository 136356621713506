
.panel-default {
    a,:link {
        //text-decoration: underline;
        &.showhide {
            color: @link-color;
        }
        &.moreLink {
            color: @btn-default-bg;
        }
        &.lessLink{
            color: @btn-default-bg;   
        }
    }

    .seeMoreFacetsLink{
        a{
            color: @btn-default-bg;
        }
    }

    .table-header {
        padding: 0 0 10px 0;
        margin-left: -3px;
        a,:link {
            float:right;
            padding: 0 15px;
            color: @gray-light; 
            &.active {
               color: @brand-secondary; 
            }
        }
        .explain-text,
        .selectall {
            font-size:12px;
            color: @text-color;
        }
        .selectall {
            font-weight: normal;
            float:right;
            margin-bottom:0;
            padding: 0 5px;
        }
    }
    
    .list-facet-by{
        li{
            a{
                &.std-display{
                    color: @btn-default-bg;
                }
            }
        }
    }
    
   .navigationBar {
       display: inline-block;
   }  
} 

.share-nav {
    &.panel-default {
        .panel-body {
            text-align: center;
            > a {
                border: 1px solid @share-icon-color;
                color:  @share-icon-color;
                border-radius: 50%;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                text-align: center;
                text-decoration: none;
                width: 40px;
                margin: 3px 6px;
                font-size: 16px;
                &:hover {
                    border: 1px solid @share-icon-hover-border-color;
                    background: @share-icon-hover-bg;
                    color: @share-icon-hover-color;
                }
            }
        }
    }
}

.navigationBar {
.paginationNumberOfItemsDisplayed, 
    .inactiveLink {
        color: @pagination-active-bg;
    }
}  