
.publisherlogodiv {
    padding: 0;
}

.publisherdetail {   
            h1 {
            margin-top: 0;
        
            }
            margin-bottom: 15px;
}

.publistwrapper {
	.panel-title {
		&.no-results { 
			margin-bottom: 20px;
			margin-top: 16px;
			text-align: right;
			width:49%;
		}
	}
}

.pub-logo {
     float:left;
     max-width: 200px; 
     height: auto; 
     padding: 10px 20px 0 0;
}
