/*import path changes go ere:*/
@common-less-dir-path: '../../common/less/';
@common-less-dir-path-platform: "../../../common/less/platform/";

/**
 * @license
 * MyFonts Webfont Build ID 3268632, 2016-08-15T07:28:17-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: DINNextLTPro-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next/pro-bold/
 * 
 * Webfont: DINNextLTPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next/pro-light/
 * 
 * Webfont: DINNextLTPro-Regular by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/din-next/pro-regular/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3268632
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2010 Linotype GmbH, www.linotype.com. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of Linotype GmbH.
 * 
 * � 2016 MyFonts Inc
*/

/* needs to be top of stylesheet for chrome to apply css var*/
:root {
    --masthead-colour-filter: brightness(0) saturate(100%) invert(9%) sepia(21%) saturate(3491%) hue-rotate(205deg) brightness(97%) contrast(97%);
    --link-default-text-decoration: underline;
}


/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/31e018");

  
@font-face {font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 700;
    //src: url('bespoke-fonts/31E018_0_0.eot');
   // src: url('bespoke-fonts/31E018_0_0.eot?#iefix') format('embedded-opentype'),
    src: url('bespoke-fonts/31E018_0_0.woff2') format('woff2'),
        url('bespoke-fonts/31E018_0_0.woff') format('woff');
   // url('bespoke-fonts/31E018_0_0.ttf') format('truetype');
}

@font-face {font-family: 'DINNextLTPro';
    font-style: normal;
    font-weight: 600;
    //src: url('bespoke-fonts/31E018_0_0.eot');
   // src: url('bespoke-fonts/31E018_0_0.eot?#iefix') format('embedded-opentype'),
    src: url('bespoke-fonts/31E018_0_0.woff2') format('woff2'),
        url('bespoke-fonts/31E018_0_0.woff') format('woff');
   // url('bespoke-fonts/31E018_0_0.ttf') format('truetype');
}
 
  
@font-face {font-family: 'DINNextLTPro';
       font-style: normal;
       font-weight: 300;
    //src: url('bespoke-fonts/31E018_1_0.eot');
    //src: url('bespoke-fonts/31E018_1_0.eot?#iefix') format('embedded-opentype'),
    src: url('bespoke-fonts/31E018_1_0.woff2') format('woff2'),
    url('bespoke-fonts/31E018_1_0.woff') format('woff');
    //url('bespoke-fonts/31E018_1_0.ttf') format('truetype');
}
 
@font-face {font-family: 'DINNextLTPro';
      font-style: normal;
      font-weight: 400;
   // src: url('bespoke-fonts/31E018_2_0.eot');
   // src: url('bespoke-fonts/31E018_2_0.eot?#iefix') format('embedded-opentype'),
      src: url('bespoke-fonts/31E018_2_0.woff2') format('woff2'),
         url('bespoke-fonts/31E018_2_0.woff') format('woff');
       //  url('bespoke-fonts/31E018_2_0.ttf') format('truetype');
}

@font-face {font-family: 'DINNextLTPro';
      font-style: normal;
      font-weight: 500;
   // src: url('bespoke-fonts/31E018_2_0.eot');
   // src: url('bespoke-fonts/31E018_2_0.eot?#iefix') format('embedded-opentype'),
      src: url('bespoke-fonts/31E018_2_0.woff2') format('woff2'),
         url('bespoke-fonts/31E018_2_0.woff') format('woff');
       //  url('bespoke-fonts/31E018_2_0.ttf') format('truetype');
}
 


@import "platform/base.less";
@import "base/__variables.less";

@import "base.less";



 