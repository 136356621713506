  @media (max-width: @screen-xxs-max) {
   .slick-slider {   
        .slick-arrow {
            display:none !important;
        }
        h3 {
            margin-top: 12px;
        }    
    }
    
  }
  
  /*  Carousel styling */
/* add bespoke no padding to main container if home page to widen area for carousel */
.home-page-no-padding {
    padding:0px;
}
.carousel-caption {
    bottom: 9px;
}
.carousel-indicators {
    bottom: 5px;
}


.carouselContainerSection {
    line-height: (@line-height-computed*0.95);// default all line heights
    margin:0 -15px;
     h2,h3,h4 {
         font-weight: 700;
     } 
 } 
 
 
 .carousel-main-navigation {
    h4 {
        margin-bottom: 0;
        margin-right: -30px;
        margin-top: 0;
    }
    p {
        margin-bottom:0;
        font-size: ceil((@font-size-base * 0.75)); 
        margin-top: 5px;
    }
    
    .carouselselectorbox {
        padding-left:30px;
    }
    
    
    img {
        margin: -10px -15px;/* o */
    }
 }
 
 .carouselContainerSection {

     .carousel-inner {
        border: 1px solid @grey-light-custom;
    
        
            .img-responsive {
            margin-top: 17px;
            margin-bottom: 17px;
            float:left;
            }
         .carouselitembox {
            margin-top:0;
            margin-bottom:13px;
            padding-left:0;
            margin-left: -7px;
            //padding: 0 15px 0 0;
          } 
          .row {
            margin:0;
          }
        }

 }
.carousel-main-navigation {
     .carousel-thumb-image { 
         //width: 28.08988764044944%;
         float:left;
         height: 69px;
     }
     
     .carousel-thumb-text {
         width: 71.91011235955056%;
         float:left;
     }
    
    .list-group-item {
        margin-bottom: 0; //override bootstrap
    }
  }
  
  #carousel-home {
    .carousel-inner {
        background-color: @gray-lighter;
        border:none;
        }
        
    .carousel-main-navigation .row {
          background-color: @gray-light;
           border: none;
           height: 69px;
          &.active {
              background-color: @gray;
              color:@text-color;
      
          }   
     }

   }
   
   @media (min-width: 992px) {
        .carouselContainerSection {
            margin: 0;
        }

   }
   
   /* cycle carousel configuration options */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('bespoke-fonts/slick.eot');
  src: url('bespoke-fonts/slick.eot?#iefix') format('embedded-opentype'), url('bespoke-fonts/slick.woff') format('woff'), url('bespoke-fonts/slick.ttf') format('truetype'), url('bespoke-fonts/slick.svg#slick') format('svg');
}

.background-image-main--carousel {
    height: auto;
    /*margin-top: 90px; */
}

.background-image-main--carousel .background-image-main__title {
    top:44%;
    margin-top:10px;
}   

/* only display slider images once slider script has loaded */
.cycle-slideshow .cycle-slideshow__slide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: @cycle2-carousel-backgrond-color;
}
.cycle-slideshow .cycle-slideshow__slide img {
  width: 100%;
  margin: unset;
}
.cycle-slideshow .cycle-slideshow__slide.cycle-slide {
  display: block;
  width: 100%;
  height: 100%;
}
.cycle-slideshow .cycle-slideshow__content {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  top: 0;
  width: 100%
}
.cycle-slideshow .cycle-slideshow__logo {
  position: absolute;
  top: 20%;
  left: -3%;
  width: 36%;
}
.cycle-slideshow .cycle-slideshow__text {
  position: absolute;
  top: 50%;
  right: -2%;
  width: 35%;
  transform: translateY(-50%);
}

.cycle-pager {
  font-family: slick;
  font-size: 20px;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
}
@media only screen and (max-width: 600px) {
  .cycle-pager {
    display: none;
  }
}
.cycle-pager span {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  color: @cycle2-carousel-pager-color;
}
.cycle-pager span.cycle-pager-active {
  color: @cycle2-carousel-pager-active-color;
}
.cycle-pager a.cycle-slideshow__pause,
.cycle-pager a.cycle-slideshow__pause:active,
.cycle-pager a.cycle-slideshow__pause:visted,
.cycle-pager a.cycle-slideshow__pause:link {
  color: @cycle2-carousel-pause-link-color;
}
.cycle-pager a.cycle-slideshow__pause:hover {
  color: @cycle2-carousel-pause-link-hover-color;
}
.cycle-slideshow,
.cycle-slideshow * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cycle-slideshow {
  margin: 0 auto 10px;
  padding: 0;
  position: relative;
  background: url(/images/jp/spinner.gif) 50% 50% no-repeat;
  width: 100%;
  min-height: 84px;
  z-index: 0;
  /* slideshow images (for most of the demos, these are the actual "slides") */
  /* in case script does not load */
  /* caption */
  /* overlay */
  /* prev / next links */
  /* display paused text on top of paused slideshow */
}
@media (min-width: 1200px) {
  .cycle-slideshow {
    min-height: 273px;
  }
}
.cycle-slideshow .cycle-slideshow__slide {
  display: none;
}
.cycle-slideshow .cycle-slideshow__slide.cycle-slide {
  display: block;
}

.cycle-slideshow .cycle-slideshow img {
  /* 
        some of these styles will be set by the plugin (by default) but setting them here
        helps avoid flash-of-unstyled-content
        */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  display: block;
}
.cycle-slideshow .cycle-slideshow img:first-child {
  position: static;
  z-index: 100;
}
.cycle-slideshow .cycle-caption {
  position: absolute;
  color: @cycle2-carousel-caption-color;
  bottom: 15px;
  right: 15px;
  z-index: 700;
}
.cycle-slideshow .cycle-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 15px;
}
.cycle-slideshow .cycle-prev,
.cycle-slideshow .cycle-next {
  position: absolute;
  top: 0;
  width: 60px;
  z-index: 800;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.cycle-slideshow .cycle-prev {
  left: 0;
}
.cycle-slideshow .cycle-next {
  right: 0;
  justify-content: flex-end;
}
.cycle-slideshow .cycle-slideshow__chevron-container {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.cycle-slideshow .cycle-slideshow__chevron {
  color: @cycle2-carousel-chevron-color;
  opacity: .6;
  filter: alpha(opacity=60);
  font-size: 24px;
}
.cycle-slideshow .cycle-next .cycle-slideshow__chevron-container {
  right: -30px;
}
.cycle-slideshow .cycle-next .cycle-slideshow__chevron-container .cycle-slideshow__chevron {
  padding-left: 10px;
}
.cycle-slideshow .cycle-prev .cycle-slideshow__chevron-container {
  left: -30px;
  justify-content: flex-end;
}
.cycle-slideshow .cycle-prev .cycle-slideshow__chevron-container .cycle-slideshow__chevron {
  padding-right: 10px;
}
.cycle-slideshow .cycle-slideshow:hover .cycle-slideshow__chevron-container,
.cycle-slideshow .cycle-slideshow:hover .cycle-slideshow__chevron-container {
  background-color: @cycle2-carousel-chevron-background-color;
}
.cycle-slideshow .cycle-slideshow:hover .cycle-slideshow__chevron,
.cycle-slideshow .cycle-slideshow:hover .cycle-slideshow__chevron {
  opacity: .9;
  filter: alpha(opacity=90);
}
.cycle-slideshow .cycle-slideshow__pause {
  line-height: 1.1em;
  color: @default-background-colour;
  position: absolute;
  bottom: -7px;
  z-index: 900;
  width: 20px;
  height:20px;
  left: 57%;
  transform: translate(0%, -57%);
}
@media (max-width: 966px) {
  .cycle-slideshow .cycle-slideshow__pause {
    left: 120%;
    transform: translate(0%, -60%);
  }
}
@media (max-width: 600px) {
  .cycle-slideshow .cycle-slideshow__pause {
    display: none;
  }
}
.cycle-slideshow .cycle-slideshow__pause:hover,
.cycle-slideshow .cycle-slideshow__pausefocus {
  color: @cycle2-carousel-pause-link-hover-color;
}
.cycle-slideshow .disabled {
  opacity: .5;
  filter: alpha(opacity=50);
}
.cycle-slideshow .cycle-paused:after {
  content: 'Paused';
  color: @cycle2-carousel-paused-color;
  background: @cycle2-carousel-paused-background-color;
  padding: 10px;
  z-index: 500;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  opacity: .5;
  filter: alpha(opacity=50);
}

.cycle-slideshow .cycle-slideshow__content img {
    max-width: 700px;
    height: 90%;
}

.cycle-slideshow .cycle-slideshow__container {
  display: flex;
  width:100%;
  height:100%;
  justify-content: center; /* centre horizontal */
  align-items: center; /* centre vertical */
  .container {
        height:90%; /*reduce it from 100% to allow you to click on pagination (and miss) 
                    and not accidentally trigger the anchor navigation behaviour. */ 
        /* lets always centre its content as well */
        display: flex;
        justify-content: center; /* centre horizontal */
        align-items: center; /* centre vertical */
    }
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  .cycle-slideshow .cycle-overlay {
    padding: 4px;
    text-align: center;
  }
  .cycle-slideshow .cycle-caption {
    bottom: 4px;
    right: 4px;
  }
}
/*@media only screen and (min-width: 1176px) {
    .cycle-slideshow {
        height: 400px;
    }
}*/
.slideshowWapper img {
  max-width: 100%;
}
@media (max-width: 479px) {
  .slick-slider .slick-arrow {
    display: none !important;
  }
  .slick-slider h3 {
    margin-top: 12px;
  }
}


