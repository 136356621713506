// Kamals dropdown menu (pulled from header.less in instance)!
@media (max-width: @screen-sm-max) {
.dl-menuwrapper {
        float: right;
        z-index: 88;
        max-width: 340px;
        .dl-menu {
            margin: 0;
            transform: translateY(0px);
            -webkit-transform: translateY(0px);
            z-index: 9;
            &.dl-subview {
                li {
                    &.dl-subviewopen {
                        > a {
                            display: block;
                            text-align: center;
                            color: @default-background-colour;
                            background: @brand-secondary;
                            &:after {
                                left: 10px;
                                color: rgba(212,204,198,0.3);
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .dl-back {
                    display:none !important;
                }
            }
            &.dl-menuopen {
                -webkit-transform: translateY(0px);
                transform: translateY(0px);
            }
        }
        &:first-child {
            margin-right: -15px; 
        }
        .dl-trigger {
            position: absolute;
            right: 0;
            top: 0;
            display: none;
            margin-right: 50px;
        }
        ul {
            background: @brand-secondary;
        }
        li {
            a {
                text-decoration: none;
            }
        }
        li.dl-back:after, li > a:not(:only-child):after {
            position: absolute;
            right: 15px; 
            top: 50%;
            margin-top: -7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 0 7.5px 10px;
            border-color: transparent transparent transparent @default-background-colour;
            content: "";
        }
    }
}