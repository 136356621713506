
.clearer {
        clear: both;
}

.openurlfield .regLabel label{
    width: 200px;   
} 

textarea{
	width: 100%;
}

#blurbeditpopup{
	textarea{
		height: 97px;
		&.wym_html_val{
			font-size: 11px;
		}
	}
}

form.personalReg{
	.regLabel{
		margin-right: 20px;
	}
	.checkboxs{
		margin-bottom: 20px;
		input[type="checkbox"]{
			margin: 5px;
			display: block;
		}
	}
}

#regForm{
	.regInput{
		float: none;
		.error {
			float: none;
		}
		input{
			width: auto;
			margin: 5px;
			float: none;
		}
	}
}
.regForm {
	.robotinput {
		.error {
			float: none;
		}
	}
}
#activationpage {
	.regInput{
		float: none;
		input{
			width: auto;
			margin: 5px;
			float: none;
		}
		.error {
			float: none;
		}
	}
	.regLabel {
		text-align: right;
		float: none;
		width: auto;
		margin: 5px 0 10px 0;
	}
}

form#filter {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.main-tools-links .ui-icon {
    float:left;
}

.blurb-editor {
    cursor:pointer;
}

.admin-button(@color, @background) {
  .button-variant(@color, @background, @color);
  color: @color;
  background-color: @background;
  border-color: @color;
  &:hover,
  &:focus,
  &.focus {
    color: @background;
    background-color: @color;
    border-color: @background;
  }
}

.admin-link {
  @media screen and (min-width: @screen-md) {
    display: inline;
  }
  margin-bottom: 5px;
}

.main-tools-links {
    margin-bottom: 5px;
    list-style: none;
    padding-left: 0;
}

.btn-admin {
  .admin-button(@btn-admin-color; @btn-admin-bg);
}

.admin-link-labels {
    .table-generic;
}

#shibboleth .regInput.checkboxs input {
    float: left;
}