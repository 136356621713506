/* Advanced Search page  */

#advancedsearchpage {
	.advancedPageSize {
	    display: none;
	}
	.form-control {
		width:100%;
		padding: 0px 2px;
	}
	h2 {
		font-family:@font-family-sans-serif;
		font-size: 15px;
		text-transform:uppercase;
	}
	.searchCriteria {
	    border-top: 1px dashed @gray;
	    padding-top:7px;
	    padding-bottom:12px;
	    &.mainSearchContainer {
	    	border-top:transparent;
	    } 
	 }
	  .add-selector-dropdown {
	  	margin-bottom:8px;
	  }
	 .termInput {
    	margin-bottom:10px;
    	margin-top:10px;
	}
	.sortByInput {
    	margin-bottom:5px;
    	margin-top:14px;
	}
	.yearInput {
    	margin-top:14px;
	}
	.connector {
    	display: block;
    	text-align: center;
	}
	.submitsearch {
	    padding-bottom:16px;
	    text-align: right;
	    clear: both;
	}	
	.fa-external-link-square {
		float:right;
		color:@brand-primary;
	}
	.submitlabel {
	    min-width:120px;
	}
	/* pop up widget styling */
	.ui-dialog-titlebar {
	    background: #ffffff none repeat scroll 0 0;
	    border: 1px none #ffffff;
	    padding: 0;
	}	
	.sWDlgTrigger {
	    background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(214, 214, 214) 100%);
	    border: 1px solid @gray;
	    clear: both;
	    cursor: pointer;
	    display: block;
	    margin:0 0 10px;
	    padding:2px 5px;
	    width:98%;
	}	
	.sWDeselect {
    	float: right;
	}
	.sWControls.head {
	    position: absolute;
	    right: 15px;
	    top: 35px;
	}
	.sWControls {
	    float: right;
	}	
	.sWDlgTitle {
	    float: left;
	}	
	.sWDlgList {
	    border-top: thin solid @gray;
	    clear: both;
	    list-style: outside none none;
	}
	.sWDlgSubmit button{
		text-transform:uppercase;
		font-weight:bold;
	}
	.selectDeselectAll {
	    float: right;
	}
	.sWDlgTitle, .selectDeselectAll {
	    font-size:15px;
	    font-weight: bold;
	}
	.ui-widget-content a {
	    color: @brand-primary;
	}	
	.greyedOut {
	    opacity: 0.5;
	}
	.searchWithinContainer {
	    float:left;
	    clear:both;
	    width:100%;
        padding-top: 15px;
	}
	.searchBetweenDatesContainer {
	   float: left;
	   clear: both;
	   width: 100%;
	}
	.option1checkbox {
	   display: inline-block;
	   margin-right: 10px;
	}
	.excludesearchInput {
	    float:right;
	    label {
	        font-weight:normal;
	    }
	}
	.ui-dialog-content {
	     margin-top: -40px;
	}
	#advanced-search-form {
		.m-right{
			label{
				margin-right: 10px;
			}
		}
	}
}



/*   Media Query's    */
@media (max-width: @screen-tablet) {
	#advancedsearchpage {
		#advanced-search-form {
			.col-sm-1 > span{
				display: block;
				margin: 8px 0;	
			}
			.m-right {
				margin-top: 15px;
				label {
					display: block;
					margin-right: 0;
					margin-top: 5px;
					margin-bottom: 10px;
				}
			}
		}
	}	
}	



/* small, Medium devices  */
@media screen and (max-width: @screen-sm-max) {
	#advancedsearchpage {
/*		.termInput {
    		margin-bottom:0;
    	}
*/		.ui-dialog.ui-widget-content {
			width:90%!important;
		}
		.sWDlgList {
    	   padding-top:51px;
		}
	}
}
/* small, Medium devices  */
@media screen and (max-width:400px) {
	#advancedsearchpage {
		.sWControls.head {
		    top:61px;
		}
		
		/*.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4,
        .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, 
        .col-xs-9, col-xs-10, .col-xs-11, .col-xs-12 {
            //override bootstrap padding at mobile resolution.
            padding-left:4px;
            padding-right:4px;
        }*/
       
        .submitsearch {
            margin: 15px;
        }
	}

}
