 /* sticky footer fix */
html, body {
    height: 100%;
}
#wrapper {
  height: auto !important;
  margin: 0 auto 0;
  @media (min-width:@screen-xs-min) { 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

#homepage #wrapper {
    overflow: hidden;
}

.footer { 
    &.footer--skinny {
      padding: 35px 0 5px;
      @media (max-width:@screen-xs-max) { 
          padding: 13px 0 5px;
      }
      .footer-menu {
          ul {
            list-style: none;
            margin-top: 10px;
            margin-bottom: 12px;
            padding-left: 0;
            float: left;
            > li {
              padding: 0 10px;
              border-right: 1px solid #ffffff;
              line-height: 12px;
              margin: 5px 0;
              float: left;
              &:first-child {
                padding-left: 0;
                border: none;
              }
              &:last-child {
                border: none;
              }
              a {
                display: inline-block;
                &:hover {
                  text-decoration: none;
                }
             }
           }
        }
      }
    }
    ul {
       padding-left: 0;
       list-style: none;
       > li {
        padding: @padding-base-vertical 0;
  }
  }
  .container-fluid h3 {
      font-weight: normal;
      border-bottom: 1px solid #e0e0e0;
  }
  
  
  .logo-footer {
      padding:10px;
      max-width: 150px; //this is for SVG images that have no fixed width and height
  }
}


@media (min-width:@screen-sm) { 
  .footer {  
    .logo-footer {
      position:absolute;
      bottom: 5px;
      right: 20px;
      padding:0; 
      max-height: 60px;
    }
    &.footer--skinny {
        .footer-menu {
            ul {
              padding-right: 110px;
            }
        }
    }
  
  }
}