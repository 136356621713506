


	/* ########################################################## */
	/* Side bar elements - Sign in */
		/* ### Tools and Share Nav */


/* sign in area */
.sign-in-container {
	border: 1px solid @gray-light;
	padding: 25px 10px 7px 10px;
	position:@mobile-menu-position;
	top:0;
	right:0px;
	left: 0px;
	margin: auto;
	background-color: fade(@navbar-default-bg, @mobile-menu-opacity);
	z-index: 70;
	width: 98%;
	.forgot-password-link {
	    float:right;
	    line-height: 2em;
	 }
	 
	 .close-window {
	   position: absolute;
       top: 0;
       right: 9px;
	 }

}

.connect-orcid-button{
	border: 1px solid #D3D3D3;
	padding: .3em;
	background-color: @default-background-colour;
	border-radius: 8px;
	box-shadow: 1px 1px 3px #999;
	cursor: pointer;
	color: @link-color;
	font-weight: bold;
	font-size: .8em;
	line-height: 24px;
	vertical-align: middle;
}

.connect-orcid-button:hover{
	border: 1px solid #338caf;
	color: #338caf;
}

.orcid-id-icon{
	display: block;
	margin: 0 .5em 0 0;
	padding: 0;
	float: left;
}
	

.upDownCaret:before {
	content: @fa-var-caret-up;
}

.collapsed .upDownCaret:before {
	content: @fa-var-caret-down;
}

.advert-placeholder {
    background-color: #dae8fc;
    border: 1px solid #6c8ebf;
    text-align:center;
    h2 {
        margin: 120px 0;
        font-weight: bold;
        
    }
}

.sidebar-pub2web-container .sidebar-pub2web-container__items {
    margin-bottom: 15px;
}

.advert-placeholder__img {
    width:100%;
}

.header-sidebar {
    padding-top: 10px;
    padding-left:0;
}
 @media (max-width: @screen-sm-max) {
    .addtofavourite {
        padding: 10px;
        display: block;
    }
    
    .content-alert-link  {
        padding: 10px;
        display: block;
    }
}



