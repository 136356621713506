a,a:hover,a:focus{
    outline: none;
}

.is-enabled-focus-state a:focus-visible,
.is-enabled-focus-state button:focus-visible,
.is-enabled-focus-state input:focus-visible,
.is-enabled-focus-state select:focus-visible
 {
    outline-offset: 2px !important;
    outline: 2px solid #555 !important;
}

.articleSourceTag {
    .externallink{
        display: block;
    }
    a[rel="external"] {
        display: inline-block;
    }
    .entry-content{
        margin:8px 0 3px 0; 
    }
}

 .tweets-blogs {
    #tweets {
        overflow: auto;
        height: 320px;
    }
}

.overlayForm {
    .runtimeP2wRecaptcha {
        padding: 10px 15px;
        text-align: right;
        .checkbox {
            display: inline-block;
            margin-right: 5px;
            min-height: auto;
        }
    }
}

.captcha-inline {
    text-align: right;
    .runtimeP2wRecaptcha {
        display: inline-block;
        padding-right: 0;
    }
    .error {
        display: inline-block;
    }
}

.order-search-filter {
    .form-control {
        min-width: 250px;
        margin: 0 10px;
     }
}

@media (min-width: @screen-desktop) {
    .advanced-search {
        padding-right: 0;
    }
    .header-sidebar .sign-in-container {
        background-color: transparent;
    }
    .footer-sidebar {
        float:right;
    }
    .slick-carousel.slick-slider {
        margin: 0;
        margin-bottom: 30px;
    }
}

