/*
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Selectable 1.10.3
 * http://jqueryui.com/selectable/
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */

.ui-selectable-helper {
	position: absolute;
	z-index: 100;
	border: 1px dotted @ui-black;
}
