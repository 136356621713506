.main-navigation-menu {
    margin-top: 7px;
        margin-left: 5px;
      position: relative;
     .main-navigation-menu__button {
        padding: 6px 10px;
        border: none;
        display: block;
        background-color: @default-background-colour;
              &:focus {
                  outline: none;
              }
            span.icon-bar {
                background: @masthead-icon-link-menu-colour;
                margin: 1px 0 7px;
                display: block;
                width: 28px;
                height: 2px;
                border-radius: 1px;
                }
        }
     .main-navigation-menu__button[aria-expanded="true"] {
        span.icon-bar {
            background: @masthead-icon-link-menu-colour;
            width: 28px;
            margin: 5px 0;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            transition: all .3s ease;
        }
        span.icon-bar.bar-one {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: relative;
            top: 6px;
        }
        span.icon-bar.bar-two {
            display: none;
        }
        span.icon-bar.bar-three {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            position: relative;
        }
    
    }
    .main-navigation-menu__dropdown {
        position: absolute;
        top:44px;
        right:0;
        z-index:2;
            //this class is added by bootstrap when the menu is fully visible
            //it is added to this class as otherwise when the menu expands it look a little odd.
            &.in {
            max-height: calc(100vh - 70px);
            overflow-y: auto;
        }
    }
 
}

.main-navigation-dropdown {
    min-width: 450px;
    max-width: 600px;
    width:auto;
    @media (max-width: 600px) {
        min-width: unset;
        max-width: none;
        width: 100vw;
    }
    -webkit-box-shadow: 0px 0px 7px 0px rgba(51,51,51,1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(51,51,51,1);
    box-shadow: 0px 0px 7px 0px rgba(51,51,51,1);
    background-color: @default-background-colour;
  .main-navigation-dropdown__menu {
    margin:20px;
    &:after {
      content: "";
      clear: both;
      display: block;
    }
  }
  .main-navigation-dropdown__menu,
   .main-navigation-dropdown__submenu {
            padding-left:0;
            list-style: none;
        }
        .main-navigation-dropdown__item {
            border-bottom: 1px solid @link-color;
            position:relative;
            clear: both;
            float: left;
            width: 100%;
            &:last-child {
                border: none;
            }
        }
         .main-navigation-dropdown__link-list {
             display: inline-block;
             padding: 16px 16px 16px 0;
             font-size: @font-size-hamburger-main-menu-link-list;
             &:link,&:active,&:visited {
                color: @hamburger-main-menu-link-color;
            }
             &.active:link,
             &.active:active,
             &.active:visited {
                   color: @brand-success;
             }
            color: @hamburger-main-menu-link-color;  
         }
        
        .main-navigation-dropdown__link {
            padding: 16px 22px 16px 0; 
            display: block;
            white-space: nowrap;
            &:link,&:active,&:visited {
                color: @hamburger-main-menu-link-color;
            }
            color: @hamburger-main-menu-link-color
        }
        .main-navigation-dropdown__toggle-expand {
            position:absolute;
            right: 0;
            width:40%;
            text-align:right;
            cursor: pointer;
            top: 0px;
        }
        .main-navigation-dropdown__chevron {
            line-height: 56px;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-property: -webkit-transform;
            -moz-transition-property: -moz-transform;
            -o-transition-property: -o-transform;
            transition-property: transform;
            outline: 0;
            color: @hamburger-main-menu-link-color;
                 &:focus, &:active, &:hover {
                color: @hamburger-main-menu-hover-color;
            }
            &.is-active {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
           }
         }
        .main-navigation-dropdown__submenu {
            display:none;
        }
        
        .main-navigation-dropdown__submenu-item {
            width:100%;
            float: left;
        }
       
        .main-navigation-dropdown__submenu-link  {
            width:100%;
            text-align:left;
            display:block;
            padding: 10px 0;
            font-size: 14px;
            color: @hamburger-main-menu-link-color;
                 &:focus, &:active, &:hover {
                color: @hamburger-main-menu-hover-color;
            }
            &:first-child {
                padding-top: 0px;
            }
            &:last-child {
                padding-bottom: 15px;
            }
        }
        
        
      
 }