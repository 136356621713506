#markedlistspage .description {
    display:none;
}



.table-wrapper {
    display: table;
    *{
        // fix for SABINET-251 allow text to wrap.
      /* These are technically the same, but use both */
          overflow-wrap: break-word;
          word-wrap: break-word;
        
          -ms-word-break: break-all;
          
          /* Instead use this non-standard one: */
          word-break: break-word;
    }
    &.issueTocContents {
        width:100%;
    }
    .table-row {
       display:table-row;
    }
    .table-cell {
        display:table-cell;
        vertical-align:top;

         &.meta-data-container {
            padding-left: 10px;
        }  
        .more_less {
          position:absolute;
          bottom: 0;
          right: 0;
          z-index:1;
        }
    }
    .select-item-row + .meta-data-container .accessIcons {
        margin-top: 0;
    }
 
}
.usercreatedpage a  {
    text-decoration: underline;
}

.articleabstract hr,
.title a hr,
.description hr {
    //SABINET 193 turn hr's into br's
    margin:0;
    border: none;
}

.issueToc .table-header {
    width: 100%;
    clear: both;
    float: left;
    }
    
 a.readmore,
 :link.readmore {
     font-style: italic;
    color: @brand-primary; 
 }
 
.buyItem:hover {
    color: @brand-primary;
    }

    
 .keyword-panel   {
     //font-size:13px;
     li {
         padding: 5px 0;
     }
     ul:nth-child(even) {
         float:right; //for mobile overridden in tablet.less
     }
    }
  .topic-blocks {
      margin: 10px 0 0 0;
  }
    
.coverImageContainer .cover {
    max-width: 80px;
}

#articleListCollections {
    position: relative;

    .collectionControls:first-of-type {
            position: absolute;
            top:47px;
            right:0;
            @media (min-width: @screen-xs-max) {
              margin-top: 20px
            }
            + .panel-default .publist-paginated {
              margin-top:10px;  
            }
        }
    .browse-item > li {
        width:100%;
    }

}


.nav-pills{
  &.bg-nav{
    li{
      a{
        border-radius: 4px;
        display: block;
        padding: 10px;
        &:hover{
          text-decoration: none;
          background: @gray-lighter;
        }
        &:focus{
          text-decoration: none;
          background: @gray-lighter;
        }
      }
    }
  }
}

.preprintstatuslabel {
    text-align:right; 
    margin-bottom:-5px;
    color: @preprintstatus-text-color;
}