

	/* ################################################################################# 
	   #################################################################################
	
								   	Tablet View 
	*/


	
	/* 
	@media (max-width:@screen-sm-max) and (min-width:@screen-tablet) {

			// Override Bootstrap buttons and drop down nav for this ViewPoint

		    // ### mainNav 
			.main-nav-button {
				display:none;
			}
			.navbar-inverse.collapse#main-nav {
				position:fixed;
				display:block;
				left:50px;
				top:1px;
				width: 620px; 
				.navbar-nav-main-item {
					display:inline-block;
					padding-top:0;
					padding-bottom:0;
					float:left;
				}
				.navbar-nav-main-item.home {
						display:none;
				}

				.button1 {
					display:none;
				}
				.open .dropdown-menu {
						background: fade(@dropdown-bg, @mobile-menu-opacity);
						position:absolute;
						
						> li > a {
						 color: @dropdown-link-color;
						 &:hover,
						 &:focus {
						   color:  @dropdown-link-hover-color;
						   background-color: @dropdown-link-hover-bg;
						 }
						}
					}
			}


			// ### secondary Nav 
			.collapse.secondary-nav{
				display:block;
				.secondary-nav-sub-container {
					right:0px;
					top:-65px;
					position:absolute;
					.secondary-nav-list{
						.secondary-nav-list-item{
							display:inline-block;
						}
						.cart {
							display:none;
						}						
					}
				}
			}
			
	

	.mobile-home-link {
		display:none;
	}
	.fa.fa-home {
		display:inline-block;
	}
 	.navbar-nav .open .journal-listing {

	   position:absolute;
	   background:#ffffff;
	   border:1px solid rgba(0, 0, 0, 0.15);
	   box-shadow :0 6px 12px rgba(0, 0, 0, 0.176);
 	}
// auto complete drop down size  	
	.ui-autocomplete { 
	    width:390px !important;
	    z-index:6 !important;
	}
	 
	.header-sidebar {
		label {
			display:inline-block;
		}
	
	}
	
 
}
* 
*/

@media  (min-width:@screen-sm) {
    .a_to_z_mobile {
        display: none;
    }
    .a_to_z {
        display:block;
    }
    
    /* override bootstrap needed due to override @grid-float-breakpoint in __variables.less file */
    .facetContainer { 
    
        .navbar-collapse.collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
        }
    
    
      .navbar-collapse {
            width: auto;
            border-top: 0;
            box-shadow: none;
        }
        
        .btn-filter {
            display: none;
        }
    
    }
    
}

@media (min-width:@screen-sm) {   
        
        .pillscontainer {
            margin: @padding-large-vertical 0;
            }
       /* lets undo .nav-stacked behaviour to display as if the class nav-stacked class does not exist this is the desktop/tablet default */ 
        .nav-pills.nav-stacked {
            padding: 0;
            margin: 0;
           > li {
               clear:none;
             + li {
                 margin-top:0;
             } 
            }
            .dropdown-menu {
                position: absolute; /* back to bootstrap defaults! */
                width:auto; 
             
            }
        }
                /* remove padding from bootstrap sm-screens and upwards  */
        .remove-padding-sm {
            padding:0;
        }

}
  

















	


