/* Heading's Variable override */ 
@font-size-h1:           ceil((@font-size-base * 1.928)); // ~27px; 
@font-size-h2:           ceil((@font-size-base * 1.785)); // ~25px;
@font-size-h3:         	 ceil((@font-size-base * 1.357)); // ~19px;

/* Panel's Variable override */ 
@panel-body-padding:          15px 0;
@panel-heading-padding:       10px 0;
@panel-inner-border:          @dborder-color;
@panel-default-heading-bg:    @bg-transparent;
@panel-default-border:        @bg-transparent;

/* H1 override approach across the site*/
 h1{
    font-size: @font-size-h2;
    font-weight: @h1-font-weight-overide;
}

/* Panel override approach across the site of which we dont have variables availabe */
.panel{
	box-shadow: none;
	> .panel-heading{
		border-color: @panel-inner-border;
		.panel-title{
			font-size: @font-size-h3;
			font-weight: @panel-font-weight;
		}
	}
}


/*For borderless login box*/
.header-sidebar{
	.login__box{
		.list-group-item {
			padding: 6px 0;
			border: none;
		}
	}
	.loginDropdown {
		top: 64px;
		left: 0px;
	}
}
/*For borderless login box*/

@media (min-width: @screen-md-max) {
	h1{
		font-size: @font-size-h1;
	}

	.left-padding{
		padding-left: 15px;
	}
	.header-sidebar{
		.navbar-right {
			margin-right: 0;
		}
	}
}
 .modifyThisSearch .modifyThisSearch__button {
        margin-left: -15px;
}
