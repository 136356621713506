
.overlayForm .requiredindicator {
    color: @state-danger-text;
}

.body{
    .error{
        margin: 0;
    }    
}

label.error {
    color: @state-danger-text;
    font-weight: normal;
}

.input-group--password {
    padding-bottom: 22px;
    position: relative;
    label.error {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
.input-group__button--password {
    padding: 0;
}
.input-group__icon--password {
    padding: 9px 12px;
}


.error, .form-label-required, .useradmin-label-required{
    color: @state-danger-text;
}

.checkbox-inline  input[type=checkbox] {
    float:left; 
}

.form-group + .clearer {
    display: none;
}

#bellowheadercontainer{
    main {
        font-size: @font-size-base;
        .dropdown-menu {
            font-size:  @font-size-base;
        }
    }
    
    .form-group{
        overflow: hidden;
    }
    #regForm{
        .form-group{
            margin-right: -15px;
            margin-left: -15px;
            .form-control{
                margin: 0;
                font-size: 13px;
            }
            select {
                &.form-control{
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
        .submit-form-button-container{
            padding: 0;
            clear: both;
        }
        
    
        .user-questions__option-container {
            display:block;
            padding-bottom: 10px;
            margin-left:20px;
            /*     max-width: 200px; */
            padding: 5px;
            .error {
                float: right;
            }
        }
        
        .user-questions {
            background-color: @light-blue;
            
        }
        
        
        .user-questions > fieldset {
            max-width: 530px;
            margin:5px auto;
            background-color: @default-background-colour;
            > legend {
                background-color: @default-background-colour;
            }
        }
        
   
      
/*       .submit-form-button-container .btn {
          float:none;
          margin-left: 20px;
      }
      commented out as does not work well on registration page vs update registration.
      */
    }    
}

.user-questions__question-answer-container {
    margin-bottom:0;
}

.form-group{
    label{
        font-size: 14px;
        font-weight: normal;
        &.passwordadvice{
            font-size: 13px;
        }
    }    
}

.checkboxlabel{
    label{
        font-weight: normal;
    }
}

select.form-control {
    color: rgb(118, 118, 118);
    padding: @thumbnail-padding;
} 
//over ride for serch within!
#searchrefineform .form-group {
    width: auto;

}



/* start of cart forms  */

.c-Login-post-cart {
    .c-LoginForm__close {
        display: none;
    }
    .c-LoginForm__not-registered {
        display:none;
    }
    .c-LoginForm__userinput {
        display: flex;
        flex-direction: column;
        margin: 15px;
        
    }
    
    .c-LoginForm__label {
        display: block !important; //override hidden-xs class
    }
    
    .c-LoginForm__input {
        margin: 0 10px;
        width: auto;
    }
    
    .c-LoginForm__submitbutton {
        float: none !important;
        margin: 0 auto;
        display: block;
        width: 92%;
    }
    
    .c-LoginForm__password-reminder {
           display: block;
           margin: 15px;
            font-size: 14px;
            text-align: center;
    }

}
.c-Register-post-cart {
    .c-Register-post-cart__submitbutton {
        float: none !important;
        margin: 0 auto;
        display: block;
        width: 92%;
    }
    .c-Register-post-cart__desc-blurb {
            width: 80%;
            margin: 10px auto;
    }
  }

.l-column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .l-column-container__item {
        flex-grow: 1;
        flex-basis: 0;
    }
    .l-column-container__heading {
        text-align: center;
    }
    
}

@media (min-width: 768px) {
    .l-column-container {
     flex-direction: row;
        .l-column-container__item {
            padding: 20px;
            &:not(:last-child) {
                border-right: 1px solid @gray;
            }
        }
    }
    
    .c-Login-post-cart {
        .c-LoginForm__input {
           flex-grow: 1;
        }
        .c-LoginForm__label {
            width: 80px;
        }
        .c-LoginForm__userinput {
            flex-direction: row;
            align-items: center;
        }
        .c-LoginForm__submitbutton {
           width: 50%;
           max-width: 220px;
       }

    }
    .c-Register-post-cart {
        .c-Register-post-cart__submitbutton {
              width: 50%;
        max-width: 220px;
        }
     
    }    
}

.l-column-container__form {
    display: block;
    height: 100%; 
    > .l-column-container__content {
        height: 50%;
    }
}

 
/* end of cart forms  */
