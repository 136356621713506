   
 /*   tablet and above! */
     @media (min-width: @screen-md-max) {
      .left-padding{
          padding-left: 0;
        }
    }

     @media (max-width: @screen-sm-max) {
       #homepage{
          .mg-t-20{
            margin-top: 0; 
          } 
        }
    }

    @media (min-width:@screen-tablet) {
       .col-sm-6.no-padding + .col-sm-6.no-padding {
           padding-left: 15px;
        }
    }
    /* mobile and above */
     @media (min-width:@screen-xs) {
         .topic-blocks .keyword-panel ul {
             float:left; //override __browse.less
         }
     }

    /*  @media (max-width:@screen-sm-max) and (min-width:@screen-tablet) {
        //last dropdown stop it overlaping the edge of the screen.
        #dropdownMenuHelp + .journal-listing {
            left: -242px; //override
        }
    }    *    *     */