/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Tooltip 1.10.3
 * http://jqueryui.com/tooltip/
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */
.ui-tooltip {
	display: block;
	font-size: 11px;
	.ui-opacity(.80);
	position: absolute;
	visibility: visible;
	z-index: @ui-zindex-tooltip;
	max-width: 200px;
	background: @ui-black;
	border: 1px solid @ui-black;
	color: @ui-white;
	padding: 3px 8px;
	text-align: center;
	text-decoration: none;
	.ui-box-shadow(inset 0 1px 0 @ui-black);
	.ui-border-radius(4px);
	border-width: 1px;

	.arrow {
    overflow: hidden;
    position: absolute;
    margin-left: 0;
    height: 20px;
    width: 20px;
		&.bottom {
			top: 100%;
			left: 38%;
			&:after {
				border-top:8px solid @ui-black;
				border-right:8px solid transparent;
				border-bottom:8px solid transparent;
				border-left:8px solid transparent;
			}
		}
		&.top {
			top: -50%;
			bottom: 22px;
			left: 42%;
			&:after {
				border-top:6px solid transparent;
				border-right:6px solid transparent;
				border-bottom:6px solid @ui-black;
				border-left:6px solid transparent;
			}
		}
		&.left {
			top : 25%;
			left: -15%;
			right: 0;
			bottom:-16px;
			&:after{
				width:0;
				border-top: 6px solid transparent;
				border-right: 6px solid @ui-black;
				border-bottom: 6px solid transparent;
				border-left: 6px solid transparent;
			}
		}
		&.right {
			top: 26%;
			left: 100%;
			right: 0;
			bottom:-16px;
			margin-left: 1px;
			&:after{
				width:0;
				border-top: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 6px solid @ui-black;
			}
		}
		&:after {
			content : " " ;
			position : absolute ;
			height : 0 ;
			left:0;
			top: 0;
			width: 0;
			margin-left : 0 ;
			bottom : 12px ;
			box-shadow: 6px 5px 9px -9px @ui-black;
		}
	}
}

