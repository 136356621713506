#supplementary_data  {
    
    h2 {
            padding-bottom: 10px;
    }
    .browse-item {
        border: 1px solid @grey-light-custom;
        padding: 10px;
        float: left;
        clear: both;
        width: 100%;
    }

    
    h4 {
        clear: both;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        margin-bottom: 0;
    }

    
    .articleMetadata {
        display:block;
        padding-left:0;
        padding-bottom: 10px;
        font-size:12px;
        float:left;
        margin-top: 5px;
            p {
            margin-bottom:0;
            }
             dl {
                margin-bottom:0;
                 dt {
                    display: inline-block;
                    }
                  dd{
                      display: inline-block;
                      padding-left:10px;
                      a {
                          word-break:all;
                      }
                  }
          
                }
        }
    
   .ft-download-content button {
        color: inherit;
        background: transparent;
        padding: 0;
        font-weight: normal;
        
        i {
        padding-left: 10px;
        font-size: 28px;
        margin-top: 2px;
        float: right;
        }
    }
    
} 
