.smaller-text {
    font-size:@font-size-small;
    &:link {
        text-decoration: underline;
    }
}

.horizontally-centered-content {
   display: flex;
     align-items: center;
  justify-content: center;
       
 }

.sidebar-pub2web-element.altmetrics > * {
   padding: 10px;
}

@media (max-width: 844px) {
    .altmetrics {
    	.__dimensions_Badge_Legend_hover {
    		top: 100% !important;
    		left: 50% !important;
    		right: auto !important;
    		-ms-transform: translateX(-50%) !important;
    		transform: translateX(-50%) !important;
    	}
    	.__dimensions_Badge_Legend::before {
		    top: -15px !important;
		    left: 50% !important;
		    margin-left: -11.25px !important;
		    margin-top: 0 !important;
		    border-width: 0 10px 15px 10px !important;
		    border-color: transparent transparent #444 transparent !important;
		}
    }
}
#homepage #mobile-share-button {
//lets remove it for home page only otherwise clients will compliain
    display:none;
}
.altmetric-hidden {display:none;}
