
#sidebyiside_iframe {
    position:fixed;
    width:100%;
    top: @masthead-height;
    height: calc(~"100% - @{masthead-height}");
    z-index:1;
    &.sidebysideiframe-fullwidth {
        top: 0; //override default
        height: 100%; //override default
    }
}


.side-by-side-nav {
    position:fixed;
    width: 200px;
    top: 80px;
    left: 5vw;
    z-index:4;
    border: 1px solid #333;
    background-color: #FFFEF5;
    padding: 10px;
    &.side-by-side-nav--fullscreen {
       top:20px;
    }
    .side-by-side-nav__link {
        color: #1B6685; //to match that currently used in the sidebyside view.
    }
    &.side-by-side-nav-offline-enabled {
        width: 300px;
        top: 70px;
        //allow for additional links by making them block level
        a {
            display: block;
            padding: 0 0 10px 0;
        }
    }

    
}