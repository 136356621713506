
// bootstrap variables overridden

@nav-link-padding:     10px 10px;
//@gray-dark:          #7E878B;
@gray:                 #767676;
@gray-light:           #d5dfec;
@gray-lighter:         #eeeeee;
@bg-transparent:       transparent;

@link-color:    #333;

@midnight-black: #000;

@light-blue: #fafdff;

@navbar-padding-vertical: 5px;



@font-size-base: 14px;
@font-size-smaller: @font-size-base * 0.7142857142857143; // ~10px; 
@font-size-small:    @font-size-base * 0.8571428571428571; //~12px 
@font-size-larger:	@font-size-base * 1.142857142857143; // ~16px
@font-size-h1:           ceil((@font-size-base * 1.928)); // ~27px; 
@font-size-h2:           ceil((@font-size-base * 1.785)); // ~25px;
@font-size-h3:         	 ceil((@font-size-base * 1.357)); // ~19px;
         

@font-size-abstract: @font-size-base;
@font-size-fulltext: @font-size-larger;
@font-line-height-fulltext: 2;
@font-size-content-item-title: ceil((@font-size-base * 1.357142857142857)); //19px;
@font-line-height-content-item-title: ceil((@font-size-base * 1.714285714285714)); //24px;





@font-size-huge: ceil((@font-size-base * 1.75)); // ~ 28px; (used for SGM banner)



@headings-font-weight:    700;
@font-bold:    700;




@screen-xxs-max:             (@screen-xs-min - 1);
//@screen-sm:                  820px; /* needed to accommodate mast head navigation */


@font-family-sans-serif:   'DINNextLTPro', sans-serif;
 @headings-font-family:  'DINNextLTPro', sans-serif;

@screen-md: 845px; //becomes @screen-md-min after pre-pass -1
@max-full-width-search-box: 578px;
@max-width-ultra-compact-ui: 405px;
@max-width-ultra-ultra-compact-ui: 380px;

/* Point at which the navbar becomes uncollapsed. Changes as part of bug 61339. */
@grid-float-breakpoint:     @screen-md-min;
/** Point at which the navbar begins collapsing.*/
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);

@navbar-default-bg: #ffffff;
//@navbar-default-brand-hover-bg: @navbar-default-bg;


@mobile-menu-opacity: 100%; //no transparency

@brand-secondary: #141a3b; // rgb(33, 51, 104) or HSL 225, 52%, 27%
@brand-primary: @brand-secondary;
@brand-secondary-hsl: hsl(225, 52%, 27%);
@brand-success: #008461;
@brand-tertiary: @brand-success; /*008461 //custom var*/

@line-height-base: 1.5;

@open-dropdown: #1051c7;
@state-danger-text: #ff0000;

@footer-bkg-color: @brand-primary;
@footer-default-text-color: @default-background-colour;
@footer-default-link-color: @footer-default-text-color;
@footer-back-to-top-link-hover-bkg: #000;
@footer-back-to-top-link-bkg: @default-background-colour;

@dropdown-link-color: #0d0d0d;
@dropdown-link-hover-bg: @brand-secondary;
@dropdown-link-hover-color: @default-background-colour;
@dropdown-select-text-line-height: @line-height-base; //bootstrap var

@mega-menu-hover-bg: @default-background-colour;
@mega-menu-hover-color: @link-color;
@mega-menu-border-color: @brand-primary;
@mega-menu-link-color: @link-color;
@mega-menu-active-link: @link-color;

@hamburger-main-menu-hover-color: @dropdown-link-hover-bg;
@nav-pills-active-link-hover-color:@dropdown-link-hover-color;
@nav-pills-active-link-hover-bg: #006a83;

@navbar-default-link-color: @dropdown-link-hover-bg;
@navbar-default-link-hover-color: @dropdown-link-hover-bg; 
@navbar-default-link-hover-bg: @default-background-colour;
@navbar-default-link-active-color: @dropdown-link-hover-bg; 
@navbar-default-link-active-bg: @default-background-colour;

@dropdown-default-expanded-button-color: @dropdown-link-hover-bg;

@inline-search_btn-colour: @brand-secondary;
@inline-search_btn-text-colour: @default-background-colour;

//lets convert this into base branding speak see:
//https://publishingtechnology.atlassian.net/wiki/display/SABINET/Sabinet+Branding?preview=/30081132/30081131/Sabinet_Brand_Manual_11.pdf
@grey1: @gray-light;
@grey2: @gray;
@grey3: @gray-dark;

@text-color: #0d0d0d;

@masthead-icon-link-menu-colour: @navbar-default-link-color;
@hamburger-main-menu-link-color: @navbar-default-link-color;


@panel-default-text: @text-color;

@brand-default: @brand-secondary-hsl;

// when commented out the @btn-primary-bg and @btn-primary-border 
// will default to the primary color
@btn-primary-color: white;
@btn-primary-bg: @brand-default;
@btn-primary-border: @btn-primary-bg; 


//the success color is known as the secoundry colour in our docs:



@tabs-icon-fonticon-border-color: @brand-success;
@tabs-icon-fonticon-color: @brand-success;


/*pagination*/
@pagination-hover-bg: @brand-primary;
@pagination-hover-color :@navbar-default-bg;

/*navbar-default active color*/

@navbar-default-toggle-hover-bg:  transparent;




//@btn-font-weight:                bold;
@btn-default-color:              @default-background-colour;
@btn-default-bg:                 @brand-default;
@btn-default-border:             @btn-default-bg;

@btn-admin-color:              @brand-primary;
@btn-admin-bg:                 @default-background-colour;

@table-border-color:           @gray;


@light-bg-color: #f5f5f5;
@dborder-color: #dddddd;




@list-group-bg:                 transparent;
@zindex-dropdown:          1100; /* fix for bug 62182 */

// custom variables
@search-highlighted-text:    #ff0;

@platform-img-path: "/images/instance/";

@banner-filename-common: "_banner.png"; // ~ e.g "micro_banner.gif"
@banner-filename-common-tablet: "_banner_tablet.png"; 
@banner-filename-common-mobile: "_banner_mobile.png"; 



@breadcrumb-bg: transparent;
@breadcrumb-padding-horizontal: 0;

// X-Small
.pagination-xs {
  .pagination-size(1px; 5px; 14px; 0px); 
 // @padding-small-vertical; @padding-small-horizontal; @font-size-small; @border-radius-small);
}

@pager-border-radius: 0;

@mobile-menu-position: absolute;

@share-icon-color: @link-color;
@share-icon-hover-color: @default-background-colour;
@share-icon-hover-bg: @dropdown-link-hover-bg;
@share-icon-hover-border-color: @share-icon-hover-bg;

@home-page-banner-font-weight: 700;

@home-page-hero-search-box-width: 700px;

 

a.nonDisambigAuthorLink {
    /* add padding to create enough space for touch must be at least 24px by 24px*/
    padding: 7px 0;
}

.article-navigation-bar a {
    color: #0d0d0d;
}

.articleSourceTag .externallink {
    padding: 5px 15px 5px 0;
}

.issue a {
    display: inline-block;
    padding:5px 0;
}


a.showhide  {
    display: inline-block;
    padding: 5px 0;
    margin: 2px 0;
}

.meta-value a {
    display: inline-block;
    padding: 5px 0;
    margin: 2px 0
}
@media (min-width:@screen-desktop) { 
    #hidedesktop { 
        display:none;
    }
}

@media (min-width:@screen-sm-max) { 
    #hidedesktop { 
        display:none;
    }
}

#header a {
    text-decoration: none;
}

.is-enabled-focus-state #footer  a:focus-visible,
.is-enabled-focus-state .home-jumbotron button:focus-visible,
.is-enabled-focus-state .home-jumbotron input:focus-visible {
    outline: 3px solid #a3e9b9 !important;
}