
#managecommentspage{
	fieldset.commentsearch{
		width:100%;
		.std-label,label{
			width:100%!important; 
		} 
		.datefromto{
			overflow:hidden;
			margin:0 -15px 25px -15px; 
			.startdate,.enddate{
				width:50%;
				padding:0 15px;
				@media(max-width:848px){
					width:100%;
				} 
			} 
		}
		.searchdetails{
			margin:10px -15px 0 -15px;
			.searchdetail{
				width:50%;
				padding:0 15px; 
				@media(max-width:848px){
					width:100%;
				} 
			}
		}
	}	
}

/*.searchbutton,.trackorder{
	float:none!important;
	input[type="submit"]{
		background-color: #a0191f;
		border-color: #a0191f;
		color: @default-background-colour;
		border:none;
		padding:6px 12px;
		font-size:16px;
		font-weight:bold;
		&:hover{
			background-color: #741216;
			border-color: #6b1115;
			color: @default-background-colour;
		}    
	}
}*/

#trackorderexplanation,.eic-intro{
	margin-top: 15px;
}
#trackdetails .regInput input.checkbox{
	margin-top:6px; 
}




@media(max-width:848px){
	.regInput{
		select{
			margin-bottom:16px!important; 
		}
	}
	.footer-sidebar{
		margin-bottom:20px; 
	} 
}

 #markedlistspage #dialog {
        .form-field label {
            width: 50%;
            text-align: right;
        }
        .panel-default {
            border:none;
        }
    }
    
#objectalerts {
    padding-top: 10px;
}
       