#footer{
    background-color: @brand-secondary;
    color: @default-background-colour; 
    position:relative;
    margin-top: 60px;
    width: 100%;

    a:link, a:active, a:visited { 
        color: @footer-link-color;  
        text-decoration: underline; 
    }
    a:hover {
        text-decoration: underline;
    }

    h3 {
        color: @navbar-default-bg;
        font-weight: @footer-heading-weight;
    }
    .backToTopLink{
        a{
            position:fixed;
            background:@brand-tertiary;
            color: @back-to-top-link;
            bottom: 67px;
            right: 3%;
            padding:8px;
            border-radius:50%;
            outline:none; 
            z-index:8; 
            i{
                font-size:23px; 
            }
            &:hover{
                background:#000; 
            }  
        }
    }

    p {
        float:left;
    }
    
    .footer-issn-text {
        float:right;
    }
}
