 
.banner-container {
    //border:1px solid @gray-lighter;
    position:relative;
    min-height: 103px;
    display: block;
    @media (min-width:@screen-desktop) { 
         margin-right: 0px;
    }
    
    p,p:link,p:active,p:hover,p:visited {
        color: @gray-dark;
        font-size: @font-size-smaller;
    }
    
 
    h1 {
        text-transform: uppercase;
        margin-top:0;
        display: inline-block;
     
    }
    

     .pull-right {
         min-height: 99px;
         position: absolute;
         top:0;
         right:0;
         height:100%;
     }
      
     .pull-left {
         padding-top:3px;
         min-height: 99px;
         position: relative;
         
     }
     
     .pull-left img {
         position: absolute; 
         top: 50%;  
         transform: translate(0, -50%);
         -webkit-transform: translate(0, -50%); //needed for safari and stock android browser see http://caniuse.com/#search=transform
          @media (min-width:@screen-phone) {
              top: 40%;  
          }
     }
  
     
     
}

//mobile background images (first) 

 .journal-banner-micro {
   .pull-right{
            background: #fff  url('@{platform-img-path}micro@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}

 .journal-banner-jmm {
   .pull-right{
            background: #fff  url('@{platform-img-path}jmm@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}

 .journal-banner-ijsem {
      @media (min-width:@screen-tablet) {
          h1 {
              font-size: @font-size-huge;
          }
      }
      @media (min-width:@screen-phone) {
          .pull-left img {
              top:45%;
          }
      }
   .pull-right{
            background: #fff  url('@{platform-img-path}ijsem@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}

 .journal-banner-jmmcr {
      @media (min-width:@screen-phone) {
            .pull-left img {
             top:45%;
           }
      }
   .pull-right{
            background: #fff  url('@{platform-img-path}jmmcr@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}

 .journal-banner-jgv {
   .pull-right{
            background: #fff  url('@{platform-img-path}jgv@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}

 .journal-banner-mgen {
   .pull-right{
            background: #fff  url('@{platform-img-path}mgen@{banner-filename-common-mobile}')  no-repeat left top;
            background-size: 133px 100%;
            }
}
    
    
 
 
 
   @media (max-width:776px) and (min-width:401px) { 
        //tablet background images 
      .journal-banner-jmm {
        .pull-right{
            background: #fff  url('@{platform-img-path}jmm@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
    }
    
          .journal-banner-micro {
        .pull-right{
            background: #fff  url('@{platform-img-path}micro@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
    }
    
          .journal-banner-ijsem {
        .pull-right{
            background: #fff  url('@{platform-img-path}ijsem@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
    }
    
       .journal-banner-jmmcr {
        .pull-right{
            background: #fff  url('@{platform-img-path}jmmcr@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
    }
    
        .journal-banner-jgv {
        .pull-right{
            background: #fff  url('@{platform-img-path}jgv@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
        }
        
       .journal-banner-mgen {
        .pull-right{
            background: #fff  url('@{platform-img-path}mgen@{banner-filename-common-tablet}')  no-repeat left top;
            background-size: 258px 100%;
            }
    }
    
    
  }
 

 @media (min-width:777px) {
     //fullsize background images 
    .journal-banner-micro {
        .pull-right {
            background-color: @default-background-colour;
            background-image: url("@{platform-img-path}micro@{banner-filename-common}"); 
            background-repeat: no-repeat; 
            background-position: left top;
            background-size: 359px 100%;
            }
        
        
    }
    .journal-banner-jgv  {
        .pull-right {
            background-color: @default-background-colour;
            background-image: url('@{platform-img-path}jgv@{banner-filename-common}');
            background-repeat: no-repeat; 
            background-position: left top;
            background-size: 359px 100%;
            }
    
    }
    
    .journal-banner-jmm {
        .pull-right{
            background-color: @default-background-colour;
            background-image: url('@{platform-img-path}jmm@{banner-filename-common}') ;
            background-repeat: no-repeat; 
            background-position: left top;
            background-size: 359px 100%;
            }
    
    }
    
    .journal-banner-jmmcr {
        .pull-right{
            background-color: @default-background-colour;
            background-image: url('@{platform-img-path}jmmcr@{banner-filename-common}');
            background-repeat: no-repeat; 
            background-position: left top;
            background-size: 359px 100%;
            }
    
    }
    
    .journal-banner-mgen {
        .pull-right{
            background-color: @default-background-colour;
            background-image: url('@{platform-img-path}mgen@{banner-filename-common}?1');
            background-repeat: no-repeat; 
            background-position: left top;
            background-size: 359px 100%;
            padding-top: 20px; //override default
         }
      
    }
    
    
    .journal-banner-ijsem {
            .pull-right{
                background-color: @default-background-colour;
                background-image: url('@{platform-img-path}ijsem@{banner-filename-common}');
                background-size: 359px 100%;
                background-repeat: no-repeat; 
                background-position: left top;
            }
           
    }
    
 }
 
 
  @media (min-width:1118px) {
     //fullsize background images 
    .banner-container {
        .pull-right {
            background-position: right top;
            }
        }
    
 }
 
  @media (max-width:@screen-desktop) and (min-width:906px) { 
     .banner-container .pull-right {
         background-position: right top;
     }
     
 }


 

 